import request from '@/utils/request'

const url = '/c/searchHistory'

/**
 * 编辑搜索历史
 * @returns
 */
export function updateSearchHistoryApi(data) {
  return request({
    url: `${url}/update`,
    method: 'put',
    data
  })
}

/**
 * 分页查询搜索历史
 * @param {*} data
 * @returns
 */
export function querySearchHistoryApi(data) {
  return request({
    url: `${url}/query`,
    method: 'post',
    data
  })
}

/**
 * 删除搜索历史
 * @param {*} data
 * @returns
 */
export function deleteSearchHistoryApi(id) {
  return request({
    url: `${url}/delete/${id}`,
    method: 'delete'
  })
}

/**
 * 根据ID获取搜索历史信息
 * @param {*} id
 * @returns
 */
export function getSearchHistoryApi(id) {
  return request({
    url: `${url}/get/${id}`,
    method: 'get'
  })
}

/**
 * 查询搜索统计
 * @returns
 */
export function getSearchStatisticsApi(type) {
  return request({
    url: `${url}/getSearchStatistics/${type}`,
    method: 'get'
  })
}

/**
 * 查询搜索个数
 * @param {*} type
 * @returns
 */
export function getSearchCountApi(type) {
  return request({
    url: `${url}/getSearchCount/${type}`,
    method: 'get'
  })
}

/**
 * 查询搜索属性统计
 * @param {*} type
 * @returns
 */
export function getSearchAttributeStatisticsApi(type, operation) {
  return request({
    url: `${url}/getSearchAttributeStatistics/${type}/${operation}`,
    method: 'get'
  })
}

/**
 * 查询搜索属性值统计
 * @param {*} type
 * @returns
 */
export function getSearchAttributeValueStatisticsApi(type, productAttributeId) {
  return request({
    url: `${url}/getSearchAttributeValueStatistics/${type}/${productAttributeId}`,
    method: 'get'
  })
}
