<template>
  <el-dialog destroy-on-close class="proDialog" :title="$t('common.colorDetails.title')" :close-on-click-modal="false"
    :visible.sync="visible" width="1000px" height="1000px" @click="close()" top="5vh">
    <div class="name">{{ colorAttributeData.colorSampleName }}</div>
    <div class="details">
      <div class="tabs">
        <template v-for="(item, index) in filteredTabs">
          <div class="tabs-item" :class="{ active: currenActive === index }" @click="handlerClick(index)" :key="index">
            {{ item.name }}</div>
        </template>
      </div>
      <div class="box" v-if="currenActive === 0">
        <div class="rgb-hex" :style="{ background: colorAttributeData.hex }">
          <div class="left" :style="{ color: getHexColor(colorAttributeData.hex) }">{{ colorAttributeData.brandName }}
          </div>
          <div class="right" :style="{ color: getHexColor(colorAttributeData.hex) }">{{ colorAttributeData.productType
            }}
          </div>
        </div>
        <div class="content">
          <div class="left">
            <div class="table">
              <div class="table-item-row">
                <div class="table-name-first">Hex</div>
                <div class="color-box">
                  <div class="table-color-name">{{ colorAttributeData.hex }}</div>
                </div>

              </div>
              <div class="table-item-row">
                <div class="table-name">sRGB</div>
                <div class="value-box">
                  <template v-for="(item, index) in colorAttributeData.rgbList">
                    <div class="table-value" :class="{ 'none-line': index === colorAttributeData.rgbList.length - 1 }"
                      :key="index">
                      {{ item }}
                    </div>
                  </template>
                </div>
              </div>
              <div class="table-item-row">
                <div class="table-name">XYZ</div>
                <div class="value-box">
                  <template v-for="(item, index) in colorAttributeData.xyzList">
                    <div class="table-value" :class="{ 'none-line': index === colorAttributeData.xyzList.length - 1 }"
                      :key="index">
                      {{ item }}
                    </div>
                  </template>
                </div>
              </div>
              <div class="table-item-row">
                <div class="table-name">LCH</div>
                <div class="value-box">
                  <template v-for="(item, index) in colorAttributeData.lchList">
                    <div class="table-value" :class="{ 'none-line': index === colorAttributeData.lchList.length - 1 }"
                      :key="index">
                      {{ item }}
                    </div>
                  </template>

                </div>
              </div>
              <div class="table-item-row">
                <div class="table-name">Lab</div>
                <div class="value-box">
                  <template v-for="(item, index) in colorAttributeData.labList">
                    <div class="table-value" :class="{ 'none-line': index === colorAttributeData.labList.length - 1 }"
                      :key="index">
                      {{ item }}
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="select-box">
              <div class="select-item">
                <div class="label">{{ $t("common.color.illuminantShort") }}</div>
                <el-select v-model="dataForm.illuminant" size="mini" :placeholder="$t('common.color.illuminant')"
                  @change="illuminantChange">
                  <template slot="prefix"><span style="cursor: pointer"><i
                        style="margin-left: 78px; margin-top: 8px; width: 1px;" class="el-icon-arrow-down"></i>
                    </span></template>
                  <el-option v-for=" item in illuminantList " :key="item" :label="item" :value="item"
                    size="mini"></el-option>
                </el-select>
              </div>

              <div class="select-item">
                <div class="label">{{ $t("common.color.observerAngleShort") }}</div>
                <el-select v-model="dataForm.observerAngle" size="mini" :placeholder="$t('common.color.observerAngle')"
                  @change="observerAngleChange">
                  <template slot="prefix"><span style="cursor: pointer"><i
                        style="margin-left: 78px; margin-top: 8px; width: 1px;" class="el-icon-arrow-down"></i>
                    </span></template>
                  <el-option v-for=" item in observerAngleList " :key="item" :label="item" :value="item"
                    size="mini"></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <!-- 光谱图  -->
          <div class="spectrum-div">
            <!-- 增加曲线图 -->
            <div ref="activeDiscount" id="activeDiscount" class="spectrum-echarts"></div>
          </div>
        </div>
        <div class="bottom dashed-bottom ">
          <div class="item">
            <div class="left">
              <div class="name">
                {{ $t('accounts.common.status') }}
              </div>
              <div class="value">
                {{ colorAttributeData.state === 1 ? $t('menu.physicalColors.labSamples') :
                  $t('menu.physicalColors.productionSamples') }}
              </div>
            </div>
            <div class="right">
              <div class="name">
                {{ $t('colorSamplePage.ossUpload.factiry') }}
              </div>
              <div class="value">
                {{ colorAttributeData.factoryName }}
              </div>
            </div>
          </div>
          <div class="item">
            <div class="left">
              <div class="name">
                {{ $t('attributes.yarnType') }}
              </div>
              <div class="value">
                {{ colorAttributeData.yarnType }}
              </div>
            </div>
            <div class="right">
              <div class="name">
                {{ $t('attributes.prdSpec') }}
              </div>
              <div class="value">
                {{ colorAttributeData.yarnSpecification }}
              </div>
            </div>
          </div>
          <div class="item">
            <div class="left">
              <div class="name">
                {{ $t('attributes.fullNameIn') }}
              </div>
              <div class="com-value" :style="compositionStyle">
                <pre>
            {{ colorAttributeData.composition }}
          </pre>
              </div>
            </div>
            <!-- <div class="right">
              <div class="name">
                色差
              </div>
              <div class="value">
                {{ colorAttributeData.devalue }}
              </div>
            </div> -->
          </div>
          <div class="item">
            <div class="left">
              <div class="value" v-if="colorAttributeData.smartFabric === '1'">
                Smart Fabric
              </div>
            </div>
            <div class="right">
              <div class="value" v-if="colorAttributeData.smartColor === '1'">
                Smart Color
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box" v-if="currenActive === 1">
        <div class="bottom">
          <div class="item">
            <div class="left">
              <div class="name">
                {{ $t('attributes.prdCode') }}
              </div>
              <div class="value">
                {{ colorAttributeData.productNo }}
              </div>
            </div>
            <div class="right">
              <div class="name">
                {{ $t('attributes.orderNumber') }}
              </div>
              <div class="value">
                {{ colorAttributeData.orderNumber }}
              </div>
            </div>
          </div>
          <div class="item">
            <div class="left">
              <div class="name">
                {{ $t('attributes.colorMatchNumber') }}
              </div>
              <div class="value">
                {{ colorAttributeData.colorMatchNumber }}
              </div>
            </div>
            <div class="right">
              <div class="name">
                {{ $t('attributes.recipeNo') }}
              </div>
              <div class="value">
                {{ colorAttributeData.recipeNo }}
              </div>
            </div>
          </div>
          <div class="item">
            <div class="left">
              <div class="name">
                {{ $t('attributes.prdSort') }}
              </div>
              <div class="value">
                {{ colorAttributeData.productCategory }}
              </div>
            </div>
            <div class="right">
              <div class="name">
                {{ $t('attributes.productionProcess') }}
              </div>
              <div class="value">
                {{ colorAttributeData.productionProcess }}
              </div>
            </div>
          </div>
          <div class="item">
            <div class="left">
              <div class="name">
                {{ $t('electronicColors.common.colorCode') }}
              </div>
              <div class="value">
                {{ colorAttributeData.colorCode }}
              </div>
            </div>
            <div class="right">
              <div class="name">
                {{ $t('attributes.colorName') }}
              </div>
              <div class="value">
                {{ colorAttributeData.colorName }}
              </div>
            </div>
          </div>
          <div class="item">
            <div class="left">
              <div class="name">
                {{ $t('attributes.vatNumber') }}
              </div>
              <div class="value">
                {{ colorAttributeData.vatNumber }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box" v-if="currenActive === 2">
        <div class="bottom">
          <div class="item">
            <div class="left">
              <div class="name">
                {{ $t('attributes.prdCode') }}
              </div>
              <div class="value">
                {{ colorAttributeData.productNo }}
              </div>
            </div>
            <div class="right">
              <div class="name">
                {{ $t('attributes.productType') }}
              </div>
              <div class="value">
                {{ colorAttributeData.productType }}
              </div>
            </div>
          </div>
        </div>
        <div class="text-description">
          <p class="text-description-p" p>{{ $t('common.colorDetails.inventory.source') }}e.dye ERP ，{{
            formattedDateTime
            }}
          </p>
        </div>
        <div>
          <template>
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column prop="stosort" :label="$t('inventory.inventoryType')" width="120">
              </el-table-column>
              <el-table-column prop="storagename" :label="$t('inventory.storageLocation')" width="150">
              </el-table-column>
              <el-table-column prop="batchno" :label="$t('inventory.lotNumber')" width="150">
              </el-table-column>
              <el-table-column prop="stoqty" :label="$t('inventory.currentStockQuantity')" width="175">
              </el-table-column>
              <el-table-column prop="unit" :label="$t('inventory.unit')" width="70">
              </el-table-column>
              <el-table-column prop="brandcus" :label="$t('inventory.brandCustomer')" width="140">
              </el-table-column>
              <el-table-column prop="stoagename" :label="$t('inventory.stockAge')" width="123">
              </el-table-column>
            </el-table>
          </template>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getIlluminantTextsApi, getObserverAngleTextsApi } from "@/api/modules/colorbook";
import { viewDetailsApi } from '@/api/modules/product'
import { getStockDataApi } from '@/api/modules/colorSample'
export default {
  data() {
    return {
      idFlag: '1',
      prdcode: '',
      billcode: '',
      colorcode: '',
      labL: '',
      labA: '',
      labB: '',
      tableData: [],
      id: '',
      visible: false,
      currenActive: 0,
      productInfo: {},
      dynamicBackgroundColor: '#f2f2f2', // 默认的滚动条轨道背景颜色
      qtxName: 'Blue Azul',
      reflectanceData: [2.3012, 2.7863, 3.1758, 3.5413, 4.787, 4.8768, 5.743, 6.5473, 8.4343, 10.1072, 12.8534, 14.6305, 15.1472, 16.5313, 17.9447, 18.7481, 19.516, 20.3826, 21.2437, 22.0256, 21.999, 20.9818, 19.9285, 18.2854, 17.7829, 16.488, 15.2828, 14.2124, 13.1924, 12.2846, 11.391, 10.4009, 9.338, 8.378, 7.3414],
      treflectanceData: [],
      refLInterval: 10,
      refLLow: 360,
      refLPoints: null,
      reflectanceList: [],
      formattedDateTime: '',
      dataForm: {
        observerAngle: "10 deg",
        illuminant: "D65",
      },
      observerAngleList: [],
      illuminantList: [],

      r: '',
      tabsList: [
        {
          name: this.$t('common.colorDetails.basic'),
          value: "1",
          permission: "detail:basic"
        },
        {
          name: this.$t('common.colorDetails.details'),
          value: "2",
          permission: "detail:details"
        },
        {
          name: this.$t('common.colorDetails.inventory'),
          value: "3",
          permission: "detail:inventory"
        }
      ],
      colorAttributeData: {},
      selectData: {},

      active: ""
    }
  },
  components: {

  },

  computed: {
    compositionStyle() {
      if (!this.colorAttributeData.composition) return { fontSize: '20px' };
      return {
        fontSize: this.colorAttributeData.composition.length > 20 ? '20px' : '30px'
      };
    },
    filteredTabs() {
      return this.tabsList.filter(tab => {
        return this.isAuth(tab.permission);
      });
    },
  },
  methods: {
    getHexColor(hexStr) {
      let textColor = '#fff';
      if (!hexStr) return textColor; // 如果没有有效的hexStr，直接返回默认文本颜色

      const rgba = this.hexToRgba(hexStr);
      if (!rgba) return textColor; // 如果rgba转换失败，也返回默认颜色

      const hsv = this.rgb2hsv(rgba[0], rgba[1], rgba[2]);

      // 浅色或者黄色，字体要黑色的逻辑
      if ((hsv.s < 0.2 && hsv.v > 0.8) || (rgba[0] > 170 && rgba[1] > 170)) {
        textColor = '#333';
      }

      return textColor;
    },
    //rgb颜色转换hsv
    rgb2hsv() {
      var rr,
        gg,
        bb,
        r = arguments[0] / 255,
        g = arguments[1] / 255,
        b = arguments[2] / 255,
        h,
        s,
        v = Math.max(r, g, b),
        diff = v - Math.min(r, g, b),
        diffc = function (c) {
          return (v - c) / 6 / diff + 1 / 2
        }

      if (diff == 0) {
        h = s = 0
      } else {
        s = diff / v
        rr = diffc(r)
        gg = diffc(g)
        bb = diffc(b)

        if (r === v) {
          h = bb - gg
        } else if (g === v) {
          h = 1 / 3 + rr - bb
        } else if (b === v) {
          h = 2 / 3 + gg - rr
        }
        if (h < 0) {
          h += 1
        } else if (h > 1) {
          h -= 1
        }
      }
      return {
        h: h * 360,
        s: s,
        v: v
      }
    },
    //hex转rgb
    hexToRgba(hex) {
      return [
        parseInt('0x' + hex.slice(1, 3)),
        parseInt('0x' + hex.slice(3, 5)),
        parseInt('0x' + hex.slice(5, 7))
      ]
    },
    getCurrentDateTime() {
      const currentDate = new Date();
      this.formattedDateTime = this.formatDateTime(currentDate);
    },
    formatDateTime(date) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // 月份从0开始，需要加1
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();

      // 格式化日期时间为 "2024-3-7 1:02:34"
      const formattedDate = `${year}-${month}-${day} ${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
      return formattedDate;
    },
    padZero(number) {
      return number < 10 ? '0' + number : number;
    },
    initActiveDiscount() {
      var activeDiscount = this.$echarts.init(this.$refs.activeDiscount);
      // 数据处理
      var reflectanceData = this.reflectanceData
      var reflLow = this.refLLow;
      var reflInterval = this.refLInterval;
      if (this.idFlag === '1') {
        reflectanceData.forEach((element, index) => { reflectanceData[index] = (element / 100).toFixed(5) })
      }


      var wavelengths = [];
      for (var i = reflLow; i < reflLow + reflectanceData.length * reflInterval; i += reflInterval) {
        wavelengths.push(i + 'nm')
      }
      let option = null;
      // 曲线表配置
      option = {
        title: {
          text: this.qtxName,
          left: 'center'
        },
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          textStyle: {
            color: '#ffffff'
          },
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          },
        },
        xAxis: {
          type: 'category',
          name: this.$t('common.color.spectralGraph.wavelengthShort'),
          data: wavelengths
        },
        yAxis: {
          type: 'value',
          name: this.$t("common.color.spectralGraph.reflectance")
        },
        series: [
          {
            data: reflectanceData,
            type: 'line',
            smooth: true,
            name: '光谱反射率',
            itemStyle: {
              // 设置折线节点的样式
              normal: {
                color: function (params) {
                  // 根据节点值设置颜色
                  return params.data > 150 ? 'red' : '#013e96';

                },
              },
            },
          }
        ]
      };
      // 使用刚指定的配置项和数据显示图表。
      if (option && typeof option === "object") {
        activeDiscount.setOption(option, true);
      }
    },
    // 获取光源数据
    getSearchClick() {
      getIlluminantTextsApi().then((records) => {
        if (records.length > 0) {
          this.illuminantList = records;
        } else {
          this.illuminantList = [];
        }
      });
    },
    // 获取观察者数据
    getObserverAngleTexts() {
      getObserverAngleTextsApi().then((records) => {
        if (records.length > 0) {
          this.observerAngleList = records;
          // 2 deg 展示为2°，10 deg 展示为10° 但是传接口仍传2 deg 或者10 deg
        } else {
          this.observerAngleList = [];
        }
      });
    },
    // 改变观察者角度
    observerAngleChange() {
      let params = {
        observerAngle: this.dataForm.observerAngle,
        illuminant: this.dataForm.illuminant,
        id: this.colorAttributeData.id,
        labL: this.labL,
        labA: this.labA,
        labB: this.labB
      }
      this.init(params)
    },
    //改变光源
    illuminantChange() {
      let params = {
        observerAngle: this.dataForm.observerAngle,
        illuminant: this.dataForm.illuminant,
        id: this.colorAttributeData.id,
        labL: this.labL,
        labA: this.labA,
        labB: this.labB
      }
      this.init(params)
    },
    handlerClick(index) {
      this.currenActive = index
      if (this.currenActive === 0) {
        setTimeout(() => {
          this.initActiveDiscount()
        });

      } else if (this.currenActive === 2) {
        this.getStockData();
        this.getCurrentDateTime();
      }
    },
    getStockData() {
      let params = {
        prdcode: this.prdcode,
        billcode: this.billcode,
        colorcode: this.colorcode,
      }

      getStockDataApi(params).then((response) => {
        this.tableData = response
      })
    },
    init(item) {
      this.dataForm.illuminant = item.illuminant
      this.dataForm.observerAngle = item.observerAngle
      this.currenActive = 0
      this.labL = item.labL
      this.labA = item.labA
      this.labB = item.labB
      this.getObserverAngleTexts();
      this.getSearchClick();
      this.visible = true
      setTimeout(() => {
        this.initActiveDiscount();
      })
      // 色样
      viewDetailsApi(item).then((response) => {
        this.colorAttributeData = response
        this.prdcode = response.productNo,
          this.billcode = response.orderNumber,
          this.colorcode = response.colorCode,
          this.r = response.r
        this.reflInterval = response.reflInterval
        this.reflLow = response.reflLow
        this.reflPoints = response.reflPoints
        this.reflectanceData = response.reflectanceData
        this.qtxName = response.colorSampleName
        this.idFlag = response.id
        this.initActiveDiscount();
        this.getStockData()
      })
    },
    //打样
    SampleHandle() {
      this.sampleVisible = true
      this.$nextTick(() => {
        this.$refs.sample.init(this.dataForm.recipeId)
      })
    },
    close() {
      this.productInfo = {}
      this.visible = false
    }
  },
  beforeDestroy() {
    this.productInfo = {}
  }
}
</script>
<style lang="scss" scoped>
.details {
  padding: 0 15px;
  box-sizing: border-box;
  min-height: 700px;

  .rgb-hex {
    height: 100px;
    kerning: 300px;
    // background-color: #4271e8;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    padding: 10px;
    box-sizing: border-box;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
  }

  .tabs {
    display: flex;
    align-items: center;
    // width: 240px;

    .tabs-item {
      width: 80px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      color: #000;
      cursor: pointer;
      position: relative;
    }

    .active {
      color: #4271e8;

      &::after {
        content: ' ';
        position: absolute;
        bottom: 5px;
        left: 50%;
        right: 50%;
        transform: translate(-50%, -50%);
        background-color: #4271e8;
        height: 2px;
        width: 40px;
      }
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .table {
      border: 1px solid #ccc;
      width: 320px;
      overflow: hidden;
      height: 224px;
      border-bottom: 0;
      border-right: 0;
      border-left: 0;
      margin-top: 40px;


      .table-item-row {
        border-bottom: 1px solid #ccc;
        border-right: 1px solid #ccc;
        display: flex;
        align-items: center;

        .table-name {
          text-align: center;
          height: 40px;
          line-height: 40px;
          width: 70px;
          border-right: 1px solid #ccc;
          border-left: 1px solid #ccc;
        }

        .table-name-first {
          text-align: center;
          height: 40px;
          line-height: 40px;
          width: 70px;
          border-right: 1px solid #ccc;
          border-left: 1px solid #ccc;
        }


        .color-box {
          width: calc(100% - 80px);
          text-align: center;
        }

        .value-box {
          display: flex;

          .table-value {
            width: 80px;
            text-align: center;
            height: 40px;
            line-height: 40px;
            border-right: 1px solid #ccc;
          }

          .none-line {
            border-right: none;
          }
        }


      }
    }

    .select-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .select-item {
        display: flex;
        align-items: center;

        .label {
          font-size: 14px;
          margin-right: 8px;
        }

        ::v-deep(.el-select) {
          width: 110px;
        }
      }
    }

    .spectrum-div {
      width: 400px;
      height: 300px;
      margin-left: -30px;

      .spectrum-echarts {
        width: 100%;
        height: 100%;
        margin-top: 47px;
      }
    }
  }

  .dashed-bottom {
    border-top: 1px dashed #ccc;
  }

  .bottom {

    margin-top: 20px;

    .item {
      display: flex;
      justify-content: space-between;
      padding: 20px 30px;
      box-sizing: border-box;

      .left {
        width: 48%;
      }

      .right {
        width: 45%
      }

      .name {
        font-size: 18px;
        color: #98979a;
        margin-bottom: 10px
      }

      .value {
        font-size: 30px;
        color: #000;
      }
    }
  }

  .smart-color {
    display: flex;
    justify-content: flex-end;
    font-size: 28px;
    color: #000;
    font-weight: 500;
  }

}

.text-description {
  width: 100%;
  height: 20px;

  .text-description-p {
    font-size: 10px;
    float: right;
    color: #4271e8;
  }
}

::v-deep .el-table th {
  background-color: #f2f2f2;
  /* 浅灰色背景 */
  font-weight: bold;
  /* 字体加粗 */
}

.com-value pre {
  color: #000;
  white-space: pre-line;
}
</style>
