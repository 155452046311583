<template xmlns="http://www.w3.org/1999/html">
  <el-dialog class="proDialog" title="色差报表" :close-on-click-modal="false" :visible.sync="visible" width="1150px"
    @click="close()" top="5vh">
    <el-row>
      <el-col :span="13">
        <el-row>
          <el-col :span="14">
            <img src="@/assets/e.dye_logo.png" style="
                height: 80px;
                disply: block;
                margin-bottom: 25px;
                margin-top: -25px;
                padding: 10px;
              " />
          </el-col>
          <el-col :span="10" style="padding-top: 10px">
            <i class="el-icon-circle-plus-outline clickable" @click="addIllObsVisible = true" :title="$t('search.accurateColorSearch.compareColors.addComparisonMode')
              "></i>
            {{
              $t("common.color.illuminantShort") +
              "/" +
              $t("common.color.observerAngleShort")
            }}
            <el-select v-model="currentIllObsName" size="mini" style="width: 140px" @change="illObsChanged">
              <template slot="prefix"><span style="cursor: pointer"><i
                    style="margin-left: 112px; margin-top: 8px; width: 1px" class="el-icon-arrow-down"></i>
                </span></template>
              <el-option v-for="item in illObs" :key="item.name" :label="item.name" :value="item.name">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3">
            {{ $t("search.accurateColorSearch.compareColors.standard") }}
          </el-col>
          <el-col :span="14" :title="currentStandard.name" class="sample-name">
            <div class="id-dot" :style="{ backgroundColor: '#000000' }" style="margin-left: 2px"></div>
            {{ currentStandard.name }}
          </el-col>
          <el-col :span="7" class="comparison-zone comparison-header">
            <div>
              {{ $t("search.accurateColorSearch.compareColors.standard") }}
            </div>
            <div>
              {{ $t("search.accurateColorSearch.compareColors.sample") }}
            </div>
          </el-col>
        </el-row>

        <el-row v-for="(item, index) in currentSamples" :key="item.id">
          <el-col :span="2" v-if="index == 0" style="padding-top: 15px">
            {{ $t("search.accurateColorSearch.compareColors.sample") }}
          </el-col>
          <el-col :offset="index == 0 ? 0 : 2" :span="15" :title="item.name" class="sample-name"
            style="padding-top: 15px">
            <i class="el-icon-aim clickable make-standard" @click="swapStandard(item.id)" :title="$t('search.accurateColorSearch.compareColors.makeStandard')
              "></i>
            <div class="id-dot" :style="{ backgroundColor: colorAll[Number(item.id) - 1] }"></div>
            {{ item.name }}
          </el-col>
          <el-col :span="7" :class="index == currentSamples.length - 1
            ? 'comparison-zone comparison-last-row'
            : 'comparison-zone'
            ">
            <div class="color-comparison-square" :style="{ backgroundColor: currentStandard.hex }"></div>
            <div class="color-comparison-square" :style="{ backgroundColor: item.hex }"></div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="11">
        <div class="graph-pane">
          <div class="tab-header">
            <div class="tab-label clickable" @click="graphShown = 'labGraph'"
              :class="graphShown == 'labGraph' ? 'selected-tab' : undefined">
              <i class="el-icon-s-help"></i>
              {{
                $t(
                  "search.accurateColorSearch.compareColors.labComparisonGraph"
                )
              }}
            </div>
            <div class="tab-label clickable" @click="graphShown = 'spectroGraph'"
              :class="graphShown == 'spectroGraph' ? 'selected-tab' : undefined">
              <i class="el-icon-s-marketing"></i>
              {{
                $t(
                  "search.accurateColorSearch.compareColors.spectralComparisonGraph"
                )
              }}
            </div>
          </div>
          <div class="tab-content" :style="{ display: graphShown == 'labGraph' ? 'block' : 'none' }">
            <canvas ref="labCanvas" :width="labGraphWidth" :height="labGraphHeight"
              style="z-index: 0; position: absolute; top: 77px; right: 104px"></canvas>
            <div class="zoom-menu" style="z-index: 1000">
              <i class="el-icon-zoom-in clickable" @click="zoomIn"></i>
              <i class="el-icon-zoom-out clickable" @click="zoomOut"></i>
            </div>
            <div class="echars">
              <div id="labGraph" ref="labGraph" class="echarts-chart_4"></div>
              <div id="labGraphY" ref="labGraphY" class="echarts-chart_5"></div>
            </div>
          </div>
          <div class="tab-content" :style="{
            display: graphShown == 'spectroGraph' ? 'block' : 'none',
          }">
            <div ref="chart" id="spectroGraph" class="echarts-chart_spectro"></div>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-table :data="samplesComparisonData" style="width: 1150px" :span-method="objectSpanMethod" ref="comparisonTable">
      <el-table-column :label="$t('search.accurateColorSearch.compareColors.sample')">
        <template slot-scope="scope">
          <div class="id-dot" :style="{ backgroundColor: colorAll[Number(scope.row.id) - 1] }"></div>
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column width="120">
        <template slot="header">
          <i class="el-icon-circle-plus-outline clickable" @click="addIllObsVisible = true"></i>

          {{
            $t("common.color.illuminantShort") +
            "/" +
            $t("common.color.observerAngleShort")
          }}
        </template>
        <template slot-scope="scope">
          <i class="el-icon-delete clickable" @click="removeIllObs(scope.row.illObs.name)"
            v-if="scope.row.illObs.name != defaultObsIllName"></i>
          {{ scope.row.illObs.name }}
        </template>
      </el-table-column>
      <el-table-column prop="dl" label="ΔL*" width="65"> </el-table-column>
      <el-table-column prop="da" label="Δa*" width="65"> </el-table-column>
      <el-table-column prop="db" label="Δb*" width="65"> </el-table-column>
      <el-table-column prop="dc" label="Δc*" width="65"> </el-table-column>
      <el-table-column prop="dh" label="Δh*" width="70"> </el-table-column>
      <el-table-column label="ΔE00" width="75">
        <template slot-scope="scope">
          <div :style="{ fontWeight: containsDE00 ? 'bold' : undefined }">
            {{ scope.row.de00 }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="ΔE*ab" width="75">
        <template slot-scope="scope">
          <div :style="{ fontWeight: containsDEab ? 'bold' : undefined }">
            {{ scope.row.deab }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="ΔECMC" width="75">
        <template slot-scope="scope">
          <div :style="{ fontWeight: containsDECMC ? 'bold' : undefined }">
            {{ scope.row.decmc }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="status" :label="$t('search.accurateColorSearch.compareColors.status')" width="75">
        <template slot-scope="scope">
          <div style="font-weight: bold; color: red" v-if="scope.row.status == 0">
            {{ $t("search.accurateColorSearch.compareColors.fail") }}
          </div>
          <div style="font-weight: bold; color: green" v-else>
            {{ $t("search.accurateColorSearch.compareColors.pass") }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="colorBias" :label="$t('search.accurateColorSearch.compareColors.colorBias')" width="220">
        <template slot-scope="scope">
          <div class="color-bias black" v-if="scope.row.dl < '0'">
            {{ $t("common.color.colorDifference.darker") }}
          </div>
          <div class="color-bias white" v-if="scope.row.dl > '0'">
            {{ $t("common.color.colorDifference.lighter") }}
          </div>
          <div class="color-bias green" v-if="scope.row.da < '0'">
            {{ $t("common.color.colorDifference.greenBias") }}
          </div>
          <div class="color-bias red" v-if="scope.row.da > '0'">
            {{ $t("common.color.colorDifference.redBias") }}
          </div>
          <div class="color-bias blue" v-if="scope.row.db < '0'">
            {{ $t("common.color.colorDifference.blueBias") }}
          </div>
          <div class="color-bias yellow" v-if="scope.row.db > '0'">
            {{ $t("common.color.colorDifference.yellowBias") }}
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top: 30px; margin-left: 12px">
      <el-row :gutter="20" style="
          width: 600px;
          border-bottom: 1px solid #ebeef5;
          padding-bottom: 5px;
          margin-bottom: 5px;
        ">
        <el-col>
          <i class="el-icon-circle-plus-outline clickable" style="margin-top: 8px; margin-right: 10px"
            @click="addTolerance" :title="$t('search.accurateColorSearch.compareColors.addTolerance')"></i>
          <div style="font-weight: bold; display: inline; color: #909399">
            {{
              $t("search.accurateColorSearch.compareColors.acceptanceCriteria")
            }}
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="width: 600px; margin-top: 5px" v-for="(item, index) in diffTolerance" :key="index">
        <el-col :span="15">
          <i style="margin-top: 8px; margin-right: 10px" class="el-icon-delete clickable" @click="removeTolerance(item)"
            v-if="index != 0"></i>
          <div v-else style="margin-right: 28px; display: inline"></div>
          <div class="label">
            {{ $t("common.color.colorDifferenceFormula") }}
          </div>
          <el-select v-model="item.diffFormula" placeholder="色差公式" @change="compareColors" style="width: 150px"
            size="mini">
            <template slot="prefix"><span style="cursor: pointer"><i
                  style="margin-left: 122px; margin-top: 8px; width: 1px" class="el-icon-arrow-down"></i>
              </span></template>
            <el-option v-for="item in colorDifferenceFormulas" :key="item.value" :label="item.name"
              :value="item.value"></el-option>
          </el-select>
        </el-col>
        <el-col :span="9">
          <div class="label">
            {{ $t("search.accurateColorSearch.compareColors.acceptedRange") }}
          </div>
          <el-input-number :precision="2" :step="0.1" controls-position="right" v-model="item.tolerance"
            @change="compareColors" style="width: 100px" size="mini"></el-input-number>
        </el-col>
      </el-row>
    </div>
    <div style="display: flex; justify-content: space-between; margin-top: 30px">
      <div style="margin-top: 20px">
        {{
          $t("search.accurateColorSearch.compareColors.allRightsReservedX", {
            x: "e.dye",
          })
        }}
      </div>
      <div>
        <el-button v-if="isAuth('export_excel')" @click="exportExcel">{{
          $t("search.accurateColorSearch.compareColors.exportExcel") }}
        </el-button>
        <el-button v-if="isAuth('export_qtx')" @click="exportQTX">{{
          $t("search.accurateColorSearch.compareColors.exportQTX") }}
        </el-button>
      </div>
    </div>

    <el-dialog :title="$t('search.accurateColorSearch.compareColors.addComparisonMode')"
      :visible.sync="addIllObsVisible" width="650px" :before-close="handleClose" append-to-body>
      <div class="comparison-params-dialog">
        <div>
          {{ $t("common.color.illuminant") }}
          <illuminant-selector size="mini" width="140" @change="selectorIllChanged" class="select" />
        </div>
        <div>
          {{ $t("common.color.observerAngle") }}
          <observer-selector size="mini" width="140" @change="selectorObsChanged" class="select" />
        </div>
        <div>
          <el-button type="primary" @click="confirmAddObsIll" size="small">{{ $t("common.buttons.add") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </el-dialog>
</template>
<script>
import * as echarts from "echarts";
import ecStat from "echarts-stat";
import observerSelector from "../../../components/observerSelector.vue";
import IlluminantSelector from "../../../components/illuminantSelector.vue";
import {
  getColorimetricReport,
  exportColorimetricReportExcel,
} from "@/api/modules/product";
import Cookies from "js-cookie";
import { axiosDownFile } from "@/utils/index";
import i18n from '@/i18n'

export default {
  components: { observerSelector, IlluminantSelector },
  name: "SingleYAxisScatterChart",
  data() {
    return {
      standard: {},
      samples: [],
      standardLabData: [],
      samplesComparisonData: [],
      addIllObsVisible: false,
      visible: false,
      colorAll: [
        "green",
        "red",
        "blue",
        "orange",
        "purple",
        "grey",
        "aqua",
        "brown",
      ],
      colorDifferenceFormulas: [
        {
          value: "dE00",
          name: "CIE2000(2:1:1)",
        },
        {
          value: "dEab",
          name: "CIE1976",
        },
        {
          value: "dECMC",
          name: "CMC(2:1)",
        },
      ],
      diffTolerance: [
        {
          diffFormula: "dE00",
          tolerance: 2,
        },
      ],
      zoomLevel: 1,
      openObsIllSelector: {
        observerAngle: "D65",
        illuminant: "10 deg",
      },
      illObs: [
        {
          name: "D65/10 deg",
          illuminant: "D65",
          observerAngle: "10 deg",
        },
      ],
      currentIllObsName: "D65/10 deg",
      defaultObs: "10 deg",
      defaultIll: "D65",
      brandLogo: localStorage.getItem("brandLogo"),
      graphShown: "labGraph",
      labGraphHeight: 268,
      labGraphWidth: 340,
      canvas: null,
    };
  },
  computed: {
    defaultObsIllName() {
      return this.defaultIll + "/" + this.defaultObs;
    },
    originalData() {
      if (this.samplesComparisonData.length > 0) {
        var data = [];
        this.samplesComparisonData.forEach((item) => {
          data.push({
            value: [item.da, item.db],
            name: item.name + "|" + "△a" + "-" + "△b:",
            illObs: item.illObs,
          });
        });
        return data;
      } else {
        return [];
      }
    },
    labGraphAbData() {
      if (
        this.originalData &&
        this.originalData.length > 0 &&
        this.originalData[0].illObs
      ) {
        const filteredList = this.originalData.filter(
          (item) => item.illObs.name == this.currentIllObsName
        );
        return filteredList ? filteredList : this.originalData;
      }
      return this.originalData;
    },
    originalDataY() {
      if (this.samplesComparisonData.length > 0) {
        var data = [];
        this.samplesComparisonData.forEach((item) => {
          data.push({
            value: [0, item.dl],
            name: item.name + "|" + "△L:",
            illObs: item.illObs,
          });
        });
        return data;
      } else {
        return [];
      }
    },
    labGraphLData() {
      if (
        this.originalDataY &&
        this.originalDataY.length > 0 &&
        this.originalDataY[0].illObs
      ) {
        const filteredList = this.originalDataY.filter(
          (item) => item.illObs.name == this.currentIllObsName
        );
        return filteredList ? filteredList : this.originalDataY;
      }
      return this.originalDataY;
    },
    currentIllObs() {
      return this.illObs.filter((item) => item.name == this.currentIllObsName);
    },
    currentStandard() {
      if (this.standardLabData && this.standardLabData.length > 0) {
        const filteredList = this.standardLabData.filter(
          (item) => item.illObs.name == this.currentIllObsName
        )[0];
        return filteredList ? filteredList : { name: "Error", hex: "#000000" };
      } else {
        return { name: "Error", hex: "#000000" };
      }
    },
    currentSamples() {
      if (this.samplesComparisonData && this.samplesComparisonData.length > 0) {
        return this.samplesComparisonData.filter(
          (item) => item.illObs.name == this.currentIllObsName
        );
      } else {
        return { name: "Error", hex: "#000000" };
      }
    },
    containsDE00() {
      return (
        this.diffTolerance.filter((item) => item.diffFormula == "dE00").length >
        0
      );
    },
    containsDEab() {
      return (
        this.diffTolerance.filter((item) => item.diffFormula == "dEab").length >
        0
      );
    },
    containsDECMC() {
      return (
        this.diffTolerance.filter((item) => item.diffFormula == "dECMC")
          .length > 0
      );
    },
    remainingDiffFormulas() {
      let usedDiffFormulas = [];
      this.diffTolerance.forEach((tolerance) => {
        this.colorDifferenceFormulas.forEach((formula) => {
          console.log(tolerance.diffFormula);
          console.log(formula);
          if (tolerance.diffFormula == formula.value) {
            // 已经被用过
            usedDiffFormulas.push(formula);
          }
        });
      });
      return this.colorDifferenceFormulas.filter(
        (item) => !usedDiffFormulas.includes(item, 0)
      );
    },
    axisAndIntervalSize() {
      let axisSize = this.zoomLevel * 5;

      let intervalSize = this.zoomLevel;

      if (intervalSize > 1) {
        intervalSize = Math.round(intervalSize);
      } else {
        intervalSize = Math.round(intervalSize * 10) / 10;
      }

      if (axisSize > 1) {
        axisSize = Math.round(axisSize);
      } else {
        axisSize = Math.round(axisSize * 10) / 10;
      }
      return [axisSize, intervalSize];
    },
  },

  methods: {
    illObsChanged() {
      this.setCookies();
      setTimeout(() => {
        this.initLabGraph();
        this.initCanvas();
      }, 0);
    },
    compareColors() {
      var params = {
        standard: this.standard,
        samples: this.samples,
        illObs: this.illObs,
        diffTolerance: this.diffTolerance,
      };

      getColorimetricReport(params).then((res) => {
        if (res) {
          var samplesComparisonData = res.samples;
          samplesComparisonData.sort(
            (firstItem, secondItem) =>
              Number(firstItem.id) - Number(secondItem.id)
          );
          this.standardLabData = res.standard;
          this.samplesComparisonData = samplesComparisonData;

          setTimeout(() => {
            this.initLabGraph();
            this.initSpectroGraph();

            this.initCanvas();
          }, 0);
          this.setCookies();
        }
      });
      this.$forceUpdate();
    },
    setCookies() {
      Cookies.set("diffTolerance", JSON.stringify(this.diffTolerance));
      Cookies.set(
        "openObsIllSelector",
        JSON.stringify(this.openObsIllSelector)
      );
      Cookies.set("illObs", JSON.stringify(this.illObs));
      Cookies.set("currentIllObsName", this.currentIllObsName);
    },
    removeIllObs(illObs) {
      this.illObs = this.illObs.filter((obj) => obj.name != illObs);

      if (this.currentIllObsName == illObs) {
        this.currentIllObsName = this.illObs[this.illObs.length - 1].name;
      }
      this.compareColors();
    },
    removeTolerance(tolerance) {
      this.diffTolerance = this.diffTolerance.filter(
        (item) => item != tolerance
      );
      this.compareColors();
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % this.illObs.length === 0) {
          return {
            rowspan: this.illObs.length,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    selectorObsChanged(obsAngle) {
      this.openObsIllSelector.observerAngle = obsAngle;
      console.log(this.openObsIllSelector);
    },
    selectorIllChanged(ill) {
      this.openObsIllSelector.illuminant = ill;
      console.log(this.openObsIllSelector);
    },
    confirmAddObsIll() {
      var currentValue =
        this.openObsIllSelector.illuminant +
        "/" +
        this.openObsIllSelector.observerAngle;
      if (this.illObs.filter((obj) => obj.name == currentValue).length > 0) {
        this.$message.error(
          this.$t("search.accurateColorSearch.compareColors.illObsAlreadyAdded")
        );
      } else {
        console.log("before");
        console.log(currentValue);
        this.illObs.push({
          name: currentValue,
          illuminant: this.openObsIllSelector.illuminant,
          observerAngle: this.openObsIllSelector.observerAngle,
        });
        this.addIllObsVisible = false;
        this.currentIllObsName = currentValue;
        this.compareColors();
      }
    },
    handleClose() {
      this.openObsIllSelector = {
        observerAngle: this.defaultObs,
        illuminant: this.defaultIll,
      };
      this.addIllObsVisible = false;
    },
    addTolerance() {
      if (this.remainingDiffFormulas && this.remainingDiffFormulas.length > 0) {
        this.diffTolerance.push({
          diffFormula: this.remainingDiffFormulas[0].value,
          tolerance: 2,
        });
        this.compareColors();
      } else {
        this.$message.error(
          this.$t("search.accurateColorSearch.compareColors.allFormulasUsed")
        );
      }
    },
    initLabGraph() {
      var colorAll = this.colorAll;
      var [axisSize, intervalSize] = this.axisAndIntervalSize;
      var myChart = echarts.init(this.$refs.labGraph); // ref唯一性demo元素
      var myChartY = echarts.init(this.$refs.labGraphY); // ref唯一性demo元素
      var option;
      var optionY;
      var DIM_CLUSTER_INDEX = 2;
      var DATA_DIM_IDX = [0, 1];
      var CENTER_DIM_IDX = [3, 4];
      var step = ecStat.clustering.hierarchicalKMeans(this.labGraphAbData, {
        clusterCount: 6,
        outputType: "single",
        outputClusterIndexDimension: DIM_CLUSTER_INDEX,
        outputCentroidDimensions: CENTER_DIM_IDX,
        stepByStep: true,
      });
      var stepY = ecStat.clustering.hierarchicalKMeans(this.labGraphLData, {
        clusterCount: 6,
        outputType: "single",
        outputClusterIndexDimension: DIM_CLUSTER_INDEX,
        outputCentroidDimensions: CENTER_DIM_IDX,
        stepByStep: true,
      });
      var ANIMATION_DURATION_UPDATE = 1000;

      function renderItemPoint(params, api) {
        var coord = api.coord([api.value(0), api.value(1)]);
        var clusterIdx = api.value(2);
        if (clusterIdx == null || isNaN(clusterIdx)) {
          clusterIdx = 0;
        }
        var isNewCluster = clusterIdx === api.value(3);
        var extra = {
          transition: [],
        };

        var contentColor = colorAll[params.dataIndex];

        return {
          type: "circle",
          x: coord[0],
          y: coord[1],
          shape: {
            cx: 0,
            cy: 0,
            r: 5,
          },
          extra: extra,
          style: {
            fill: contentColor,
            shadowColor: contentColor,
            shadowBlur: isNewCluster ? 12 : 0,
            transition: ["shadowBlur", "fill"],
          },
        };
      }
      function renderBoundary(params, api) {
        var xVal = api.value(0);
        var yVal = api.value(1);
        var maxDist = api.value(2);
        var center = api.coord([xVal, yVal]);
        var size = api.size([maxDist, maxDist]);
        return {
          type: "ellipse",
          shape: {
            cx: isNaN(center[0]) ? 0 : center[0],
            cy: isNaN(center[1]) ? 0 : center[1],
            rx: isNaN(size[0]) ? 0 : size[0] + 15,
            ry: isNaN(size[1]) ? 0 : size[1] + 15,
          },
          extra: {
            renderProgress: ++targetRenderProgress,
            enterFrom: {
              renderProgress: 0,
            },
            transition: "renderProgress",
          },
          style: {
            fill: null,
            stroke: "rgba(0,0,0,0.2)",
            lineDash: [4, 4],
            lineWidth: 4,
          },
        };
      }
      function makeStepOption(option, data, centroids) {
        var newCluIdx = centroids ? centroids.length - 1 : -1;
        var maxDist = 0;
        for (var i = 0; i < data.length; i++) {
          var line = data[i];
          if (line[DIM_CLUSTER_INDEX] === newCluIdx) {
            var dist0 = Math.pow(
              line[DATA_DIM_IDX[0]] - line[CENTER_DIM_IDX[0]],
              2
            );
            var dist1 = Math.pow(
              line[DATA_DIM_IDX[1]] - line[CENTER_DIM_IDX[1]],
              2
            );
            maxDist = Math.max(maxDist, dist0 + dist1);
          }
        }
        var boundaryData = centroids
          ? [
            [
              centroids[newCluIdx][0],
              centroids[newCluIdx][1],
              Math.sqrt(maxDist),
            ],
          ]
          : [];
        option.options.push({
          series: [
            {
              type: "custom",
              encode: {
                tooltip: [0, 1],
              },
              renderItem: renderItemPoint,
              data: data,
            },
            {
              type: "custom",
              renderItem: renderBoundary,
              animationDuration: 3000,
              silent: true,
              data: boundaryData,
            },
          ],
        });
      }
      var targetRenderProgress = 0;

      option = {
        timeline: {
          show: false,
          top: "center",
          right: 30,
          height: 120,
          width: 7,
          inverse: true,
          autoPlay: false,
          playInterval: 2500,
          symbol: "none",
          orient: "vertical",
          axisType: "category",
          label: {
            formatter: "step {value}",
            position: 10,
          },
          checkpointStyle: {
            animationDuration: ANIMATION_DURATION_UPDATE,
          },
          data: [],
        },
        baseOption: {
          animationDurationUpdate: ANIMATION_DURATION_UPDATE,
          transition: ["shape"],
          tooltip: {},
          grid: {},
          xAxis: {
            name: "△a",
            type: "value",
            // 设置轴的最小值和最大值，使得0点在中间
            min: -axisSize,
            max: axisSize,
            // 控制刻度间距
            interval: intervalSize,
            // 确保轴线通过原点
            axisLine: {
              onZero: true,
              lineStyle: {
                width: "3",
                color: {
                  type: "linear",
                  x: 1,
                  y: 0,
                  x2: 0,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: "red", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "green", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
          },
          yAxis: {
            name: "△b",
            type: "value",
            // 设置轴的最小值和最大值，使得0点在中间
            min: -axisSize,
            max: axisSize,
            // 控制刻度间距
            interval: intervalSize,
            // 确保轴线通过原点
            axisLine: {
              onZero: true,
              lineStyle: {
                width: "3",
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "yellow", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "blue", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
          },
          series: [
            {
              type: "scatter",
            },
          ],
        },
        options: [],
      };

      optionY = {
        timeline: {
          show: false,
          top: "center",
          right: 30,
          height: 120,
          width: 3,
          inverse: true,
          autoPlay: false,
          playInterval: 2500,
          symbol: "none",
          orient: "vertical",
          axisType: "category",
          label: {
            formatter: "step {value}",
            position: 10,
          },
          checkpointStyle: {
            animationDuration: ANIMATION_DURATION_UPDATE,
          },
          data: [],
        },
        baseOption: {
          animationDurationUpdate: ANIMATION_DURATION_UPDATE,
          transition: ["shape"],
          tooltip: {},
          grid: {},
          xAxis: {
            show: false,
            name: "△a",
            type: "value",
            // 设置轴的最小值和最大值，使得0点在中间
            min: -5,
            max: 5,
            // 控制刻度间距
            interval: 5,
            // 确保轴线通过原点
            axisLine: {
              onZero: true,
            },
          },
          yAxis: {
            name: "△L",
            type: "value",
            // 设置轴的最小值和最大值，使得0点在中间
            min: -axisSize,
            max: axisSize,
            // 控制刻度间距
            interval: intervalSize,
            // 确保轴线通过原点
            axisLine: {
              onZero: true,
              lineStyle: {
                width: "3",
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#ffffff", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#000000", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
          },
          series: [
            {
              type: "scatter",
              symbolSize: function () {
                return 13;
              },
            },
          ],
        },
        options: [],
      };

      makeStepOption(option, this.labGraphAbData);
      makeStepOption(optionY, this.labGraphLData);

      option.timeline.data.push("0");
      optionY.timeline.data.push("0");

      for (var i = 1, stepResult; !(stepResult = step.next()).isEnd; i++) {
        makeStepOption(
          option,
          echarts.util.clone(stepResult.data),
          echarts.util.clone(stepResult.centroids)
        );
        option.timeline.data.push(i + "");
      }
      for (var i = 1, stepResult; !(stepResult = stepY.next()).isEnd; i++) {
        makeStepOption(
          optionY,
          echarts.util.clone(stepResult.data),
          echarts.util.clone(stepResult.centroids)
        );
        option.timeline.data.push(i + "");
      }

      option && myChart.setOption(option);
      optionY && myChartY.setOption(optionY);
      console.log(myChart, "mychart");
    },
    makeArrayAndDivide(list) {
      var splitList = list.split(",");
      splitList.forEach(
        (item) => (item = (Number(item.trim()) / 100).toFixed(5))
      );
      return splitList;
    },

    initSpectroGraph() {
      // !!!!! IMPORTANT !!!!!!
      // 如果反射率的数据点数量不都一致，会出现问题
      // BUG！

      var spectroGraph = this.$echarts.init(
        document.getElementById("spectroGraph")
      );

      // 数据处理
      var reflectanceData = this.makeArrayAndDivide(this.standard.r);

      var reflLow = this.standard.reflLow;
      var reflInterval = this.standard.refLInterval;
      var wavelengths = [];
      for (
        var i = reflLow;
        i < reflLow + reflectanceData.length * reflInterval;
        i += reflInterval
      ) {
        wavelengths.push(i + "nm");
      }

      var series = [
        {
          data: reflectanceData,
          type: "line",
          smooth: true,
          name: this.standard.name,
          itemStyle: {
            // 设置折线节点的样式
            normal: {
              color: "#000000",
            },
          },
        },
      ];

      var legendData = [this.standard.name];
      console.log(this.samples);

      this.samples.forEach((sample) => {
        series.push({
          data: this.makeArrayAndDivide(sample.r),
          type: "line",
          smooth: true,
          name: sample.name,
          itemStyle: {
            // 设置折线节点的样式
            normal: {
              color: this.colorAll[Number(sample.id) - 1],
            },
          },
        });
        legendData.push(sample.name);
      });

      let option = null;
      // 曲线表配置
      option = {
        title: {
          left: "center",
        },
        // legend: {
        //   data: legendData,
        // },
        tooltip: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          textStyle: {
            color: "#ffffff",
          },
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        xAxis: {
          type: "category",
          name: this.$t("common.color.spectralGraph.wavelengthShort"),
          data: wavelengths,
        },
        yAxis: {
          type: "value",
          name: this.$t("common.color.spectralGraph.reflectance"),
        },
        series: series,
      };
      // spectroGraph.setOption(option);
      // 使用刚指定的配置项和数据显示图表。
      if (option && typeof option === "object") {
        spectroGraph.setOption(option, true);
      }
    },

    // 初始化 弹窗
    init(standard, checkedlist) {
      // get data from Cookies
      try {
        const cookiesTolerance = JSON.parse(Cookies.get("diffTolerance"));
        if (cookiesTolerance && cookiesTolerance.length > 0) {
          this.diffTolerance = cookiesTolerance;
        }
      } catch (error) { }

      try {
        const cookiesOpenObsIllSelector = JSON.parse(
          Cookies.get("openObsIllSelector")
        );
        if (cookiesOpenObsIllSelector && cookiesOpenObsIllSelector.length > 0) {
          this.openObsIllSelector = cookiesOpenObsIllSelector;
        }
      } catch (error) { }

      try {
        const cookiesIllObs = JSON.parse(Cookies.get("illObs"));
        if (cookiesIllObs && cookiesIllObs.length > 0) {
          this.illObs = cookiesIllObs;
        }
      } catch (error) { }

      try {
        const cookiesCurrentIllObsName = Cookies.get("currentIllObsName");
        if (cookiesCurrentIllObsName) {
          this.currentIllObsName = cookiesCurrentIllObsName;
        }
      } catch (error) { }

      // fix data
      checkedlist.forEach((item, index) => {
        if (!item.name || (item.name == "" && item.colorSampleName != "")) {
          item.name = item.colorSampleName;
        }
        // item.id = index + 1;//bug standard.id=index+1
      });
      // colorSampleName

      this.standard = standard;
      this.samples = checkedlist;

      this.visible = true;
      this.compareColors();
      setTimeout(() => {
        this.initSpectroGraph();
      }, 0);
    },
    initCanvas() {
      // Standard values values
      const a = Number(this.currentStandard.a);
      const b = Number(this.currentStandard.b);
      const c = Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
      const hRad = Math.atan2(b, a);

      let ctx = null;
      if (this.canvas) {
        ctx = this.canvas.getContext("2d");
        ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      } else {
        const canvas = this.$refs.labCanvas;
        this.canvas = canvas;
        ctx = canvas.getContext("2d");
      }

      const halfWidthX = this.labGraphWidth / 2;
      const halfWidthY = this.labGraphHeight / 2;
      ctx.translate(halfWidthX, halfWidthY);

      // write the line

      ctx.rotate(-hRad);
      ctx.beginPath();

      // Set a start-point
      ctx.moveTo(halfWidthX / -2, 0);

      // Set an end-point
      ctx.lineTo(halfWidthX / 2, 0);
      ctx.lineWidth = 2;
      ctx.strokeStyle = "#C0C4CC";
      ctx.stroke();

      // Draw point of arrow
      const triangleWidth = 30;
      const triangleHeight = 15;
      const x = halfWidthX / 2;
      const y = 0;

      ctx.beginPath();
      ctx.moveTo(x - triangleWidth / 2, y - triangleHeight / 2);
      ctx.lineTo(x, y);
      ctx.lineTo(x - triangleWidth / 2, y + triangleHeight / 2);
      ctx.stroke();

      ctx.rotate(hRad);

      // Draw the line of equal dc
      ctx.beginPath();
      const radius = (Math.sqrt(c) * halfWidthX) / this.axisAndIntervalSize[1];
      let [centerX, centerY] = this.getArrowTip(hRad, radius);
      centerX = -centerX;
      centerY = -centerY;
      ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
      ctx.stroke();

      // find position of arrow tip:
      let [tipX, tipY] = this.getArrowTip(hRad, halfWidthX / 2);

      ctx.fillStyle = "#C0C4CC";
      ctx.font = "12px sans-serif";
      ctx.textAlign = "center";

      let [posX0, posY0] = this.adjustPosition(tipX, tipY, 0);
      ctx.fillText(
        this.$t("common.color.colorDifference.strongerColor"),
        posX0,
        posY0
      );
      let [posX2, posY2] = this.adjustPosition(tipX, tipY, 2);
      ctx.fillText(
        this.$t("common.color.colorDifference.weakerColor"),
        posX2,
        posY2
      );

      let [posX1, posY1] = this.adjustPosition(tipX, tipY, 1);
      let [posX3, posY3] = this.adjustPosition(tipX, tipY, 3);
      let [text1, text2] = this.getColorText(hRad);
      ctx.fillText(text1, posX1, posY1);
      ctx.fillText(text2, posX3, posY3);

      ctx.translate(-halfWidthX, -halfWidthY);
    },
    getColorText(hRad) {
      // Make sure angle is positive
      hRad = hRad < 0 ? hRad + Math.PI * 2 : hRad;

      if (hRad < Math.PI / 4 || hRad > (Math.PI * 7) / 4) {
        return [
          this.$t("common.color.colorDifference.blueBias"),
          this.$t("common.color.colorDifference.yellowBias"),
        ];
      }
      if (hRad > Math.PI / 4 && hRad < (Math.PI * 3) / 4) {
        return [
          this.$t("common.color.colorDifference.redBias"),
          this.$t("common.color.colorDifference.greenBias"),
        ];
      }
      if (hRad > (Math.PI * 3) / 4 && hRad < (Math.PI * 5) / 4) {
        return [
          this.$t("common.color.colorDifference.yellowBias"),
          this.$t("common.color.colorDifference.blueBias"),
        ];
      }
      if (hRad > (Math.PI * 5) / 4 && hRad < (Math.PI * 7) / 4) {
        return [
          this.$t("common.color.colorDifference.greenBias"),
          this.$t("common.color.colorDifference.redBias"),
        ];
      }
      return ["", ""];
    },
    getArrowTip(hRad, arrowLength) {
      // Make sure angle is positive
      hRad = hRad < 0 ? hRad + Math.PI * 2 : hRad;

      var tipX = 0;
      var tipY = 0;

      if (hRad > 0 && hRad < Math.PI / 2) {
        tipY = -(Math.sin(hRad) * arrowLength);
        tipX = Math.sqrt(Math.pow(arrowLength, 2) - Math.pow(tipY, 2));
      }
      if (hRad > Math.PI / 2 && hRad < Math.PI) {
        const angle = Math.PI - hRad;
        tipY = -(Math.sin(angle) * arrowLength);
        tipX = -Math.sqrt(Math.pow(arrowLength, 2) - Math.pow(tipY, 2));
      }
      if (hRad > Math.PI && hRad < (Math.PI * 3) / 2) {
        const angle = (Math.PI * 3) / 2 - hRad;
        tipX = -(Math.sin(angle) * arrowLength);
        tipY = Math.sqrt(Math.pow(arrowLength, 2) - Math.pow(tipX, 2));
      }
      if (hRad > (Math.PI * 3) / 2 && hRad < Math.PI * 2) {
        const angle = Math.PI * 2 - hRad;
        tipY = Math.sin(angle) * arrowLength;
        tipX = Math.sqrt(Math.pow(arrowLength, 2) - Math.pow(tipY, 2));
      }
      return [tipX, tipY];
    },
    adjustPosition(x, y, pos) {
      // pos = 想要的位置，顺时针
      let posX = x;
      let posY = y;

      if (pos == 1) {
        posX = -y;
        posY = x;
      }

      if (pos == 2) {
        posX = -x;
        posY = -y;
      }

      if (pos == 3) {
        posX = y;
        posY = -x;
      }

      // 调整位置
      const adjAmount = 30;
      if (posX > 0 && posY > 0) {
        posX += adjAmount;
        posY += adjAmount;
      }
      if (posX < 0 && posY > 0) {
        posX -= adjAmount;
        posY += adjAmount;
      }
      if (posX < 0 && posY < 0) {
        posX -= adjAmount;
        posY -= adjAmount;
      }
      if (posX > 0 && posY < 0) {
        posX += adjAmount;
        posY -= adjAmount;
      }

      return [posX, posY];
    },
    radToDeg(rad) {
      let angle = rad * (180.0 / Math.PI);
      if (angle < 360) {
        angle += 360;
      }
      return angle;
    },
    swapStandard(sampleId) {
      let sampleToBecomeStandard = this.samples.filter(
        (item) => item.id == sampleId
      )[0];
      sampleToBecomeStandard.id = 0;

      let standardToBecomeSample = this.standard;
      standardToBecomeSample.id = sampleId;
      this.samples[Number(sampleId) - 1] = standardToBecomeSample;
      this.standard = sampleToBecomeStandard;
      this.compareColors();
    },

    zoomIn() {
      this.zoomLevel = this.zoomLevel - this.zoomLevel * 0.2;
      setTimeout(() => {
        this.initLabGraph();
        this.initCanvas();
      }, 0);
    },
    zoomOut() {
      this.zoomLevel = this.zoomLevel + this.zoomLevel * 0.2;
      setTimeout(() => {
        this.initLabGraph();
        this.initCanvas();
      }, 0);
    },

    containsDiff(diffFormula) {
      const numOccurances =
        this.diffTolerance.filter((item) => item.diffFormula == diffFormula)
          .length > 0;

      if (numOccurances && numOccurances.length > 0) {
        return true;
      }
      return false;
    },
    exportExcel() {
      var params = {
        standard: this.standard,
        samples: this.samples,
        illObs: this.illObs,
        diffTolerance: this.diffTolerance,
      };
      axiosDownFile(`/api/c/product/exportColorimetricReportExcel`, params)
      return

    },
    exportQTX() {
      var params = {
        standard: this.standard,
        samples: this.samples,
      };
      axiosDownFile(`/api/c/product/exportColorimetricReportQtx`, params)
      return
    },

    close() {
      this.standard = {};
      this.samples = [];
      this.standardLabData = [];
      this.samplesComparisonData = [];
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.graph-pane {
  width: 500px;
  height: 400px;
  margin-left: 20px;
}

.tab-header {
  margin-left: 10px;
  opacity: 0.8;
}

.tab-label {
  display: inline;
  margin: 10px;
  padding: 5px;
}

.selected-tab {
  opacity: 1;
  color: black;
  font-weight: bold;
}

.tab-content {}

.echars {
  width: 100%;
  height: 400px;
  display: flex;
}

.echarts-chart_4 {
  width: 85%;
  height: 100%;
}

.echarts-chart_5 {
  width: 15%;
  height: 100%;
}

.echarts-chart_spectro {
  width: 500px;
  height: 400px;
}

.comparison-zone {
  background-color: #969696;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
}

.comparison-header {
  color: #fff;
  font-weight: bold;
  padding: 10px 10px 5px 10px;
  display: flex;
  justify-content: space-evenly;
}

.sample-name {
  padding-right: 15px;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}

.comparison-last-row {
  padding-bottom: 10px;
}

.color-comparison-square {
  display: block;
  background-color: red;
  height: 50px;
  width: 70px;
}

.comparison-params-dialog {
  display: flex;
  justify-content: space-between;
  font-weight: bold;

  .select {
    margin-left: 5px;
  }
}

.clickable {
  cursor: pointer;
  opacity: 0.8;
}

.clickable:hover {
  opacity: 1;
}

.color-bias {
  font-size: smaller;
  display: inline;
  border: 1px solid #c0c0c0;
  padding: 1px;
  margin: 2px;
  border-radius: 3px;
}

.id-dot {
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  margin-right: 3px;
}

.make-standard {
  margin-left: 6px;
  margin-right: 7px;
}

.zoom-menu {
  position: absolute;
  right: 15px;
}

.label {
  display: inline;
  font-size: small;
  margin-right: 5px;
}
</style>