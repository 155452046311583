import request from '@/utils/request'

const url = '/c/product'

/**
 * 分页获取产品列表信息
 * @returns
 */
export function queryProductApi(data) {
  return request({
    url: `${url}/query`,
    method: 'post',
    data
  })
}

/**
 * 创建/编辑产品
 * @returns
 */
export function addOrUpdateProductApi(data) {
  return request({
    url: `${url}/${!data.id ? 'add' : 'update'}`,
    method: 'put',
    data
  })
}

// 获取信息
export function getProductApi(id) {
  return request({
    url: `${url}/get/${id}`,
    method: 'get'
  })
}

// 检查产品编号是否重复
export function checkProductNoApi(params) {
  return request({
    url: `${url}/checkProductNo`,
    method: 'get',
    params: params
  })
}

// 搜索产品
export function searchListApi(data) {
  return request({
    url: `${url}/searchList`,
    method: 'post',
    data
  })
}


export function viewDetailsApi(data) {
  return request({
    url: `${url}/viewDetails`,
    method: 'post',
    data
  })
}

//导出产品数据
export function exportProductApi(data) {
  return request({
    url: `${url}/export`,
    method: 'post',
    data
  })
}

//导出产品数据是获取导出时间，制作进度条
export function getExportProgressApi(params) {
  return request({
    url: `${url}/getExportProgress`,
    method: 'get',
    params: params
  })
}

// 获取基础产品信息列表
export function list(params) {
  return request({
    url: '/product/product/list',
    method: 'get',
    params: params
  })
}
// 获取基础产品信息列表
export function list2(params) {
  return request({
    url: '/product/product/list2',
    method: 'post',
    data: params
  })
}

//获取下拉框产品性能列表
export function getProductPerformance(params) {
  return request({
    url: '/product/product/getProductPerformance',
    method: 'get',
    params: params
  })
}

//获取下拉框产品性能列表
export function getHex(params) {
  return request({
    url: `${url}/getHex`,
    method: 'get',
    params: params
  })
}

// 删除
export function deleteProductApi(id) {
  return request({
    url: `${url}/delete/${id}`,
    method: 'delete'
  })
}

// 修改产品状态
export function updateProductStateApi(id) {
  return request({
    url: `${url}/updateState/${id}`,
    method: 'put'
  })
}

//获取下拉框其他性能，产品表面,产品光泽
export function getDict(params) {
  return request({
    url: '/product/product/getDict',
    method: 'get',
    params: params
  })
}

//获取行业筛选列表
export function getIndustryList(params) {
  return request({
    url: '/product/product/getIndustryList',
    method: 'get',
    params: params
  })
}
//获取色系列表
export function getColorSeriesList(params) {
  return request({
    url: '/product/product/getColorSeriesList',
    method: 'get',
    params: params
  })
}
//获取产品体系列表
export function getProductHierarchy(params) {
  return request({
    url: '/product/product/getProductHierarchy',
    method: 'get',
    params: params
  })
}

//获取产品系列表
export function getProductSeries(params) {
  return request({
    url: '/product/product/getProductSeries',
    method: 'get',
    params: params
  })
}
//获取产品分类列表
export function getProductCategory(params) {
  return request({
    url: '/product/product/getProductCategory',
    method: 'get',
    params: params
  })
}
//获取工厂列表
export function getFactory() {
  return request({
    url: '/c/factory/getFactory',
    method: 'post',
  })
}
//获取工厂列表
export function getFactoryIndex(params) {
  return request({
    url: '/product/product/getFactoryIndex',
    method: 'get',
    params: params
  })
}
export function getFactory1(params) {
  return request({
    url: '/product/product/getFactory1',
    method: 'get',
    params: params
  })
}
//获取品牌列表
export function getBrand(params) {
  return request({
    url: '/product/product/getBrand',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info1(params) {
  return request({
    url: '/product/product/info1',
    method: 'get',
    params: params
  })
}

// 添加
export function add(params) {
  return request({
    url: '/product/product/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update(params) {
  return request({
    url: '/product/product/update',
    method: 'post',
    data: params
  })
}

//获取配方信息
export function selectRecipe(params) {
  return request({
    url: '/product/product/selectRecipe',
    method: 'get',
    params: params
  })
}

//获取当前用户授权设备
export function selectDevice(params) {
  return request({
    url: '/product/product/selectDevice',
    method: 'get',
    params: params
  })
}
//设备同步
export function syncDevice(params) {
  return request({
    url: '/product/product/syncDevice',
    method: 'get',
    params: params
  })
}
//设备同步
export function syncDeviceData(params) {
  return request({
    url: '/product/product/syncDeviceData',
    method: 'get',
    params: params
  })
}
//设备同步
export function syncQueryDeviceData(params) {
  return request({
    url: '/product/product/syncQueryDeviceData',
    method: 'get',
    params: params
  })
}
//根据测试值显示名称
export function selectGloss(params) {
  return request({
    url: '/product/product/selectGloss',
    method: 'get',
    params: params
  })
}

// 根据登录用户获取工厂和品牌
export function getFactoryBrand(params) {
  return request({
    url: '/product/product/getFactoryBrand',
    method: 'get',
    params: params
  })
}
//根据产品编号匹配系列
export function queryNoSeries(params) {
  return request({
    url: '/product/product/getNoSeries',
    method: 'get',
    params: params
  })
}
//根据产品编号匹配系列
export function queryNoColor(params) {
  return request({
    url: '/product/product/getNoColor',
    method: 'get',
    params: params
  })
}
// 获取测试数据
export function getDataInfo(params) {
  return request({
    url: '/product/product/getDataInfo',
    method: 'get',
    params: params
  })
}

// 提交测试数据
export function getDataInfo2(params) {
  return request({
    url: '/product/product/getDataInfo2',
    method: 'post',
    data: params
  })
}

//根据hex获取lab
export function getLABApi(params) {
  return request({
    url: `${url}/getLAB`,
    method: 'get',
    params: params
  })
}

/**
 * 获取产品概览
 * @returns
 */
export function getProductOverviewApi() {
  return request({
    url: `${url}/getOverview`,
    method: 'get'
  })
}

/**
 * 获取产品占比
 * @returns
 */
export function getValueProportionApi(params) {
  return request({
    url: `${url}/getValueProportion`,
    method: 'get',
    params
  })
}

//根据lab获取hex
export function gethex(params) {
  return request({
    url: '/product/product/getHex',
    method: 'get',
    params: params
  })
}

//获取用户账号和用户角色
export function nameAndRole(params) {
  return request({
    url: '/sys/user/nameAndRole',
    method: 'get',
    params: params
  })
}

// //更改设备状态

// export function syncDevice (params) {
//     return request({
//         url: '/product/product/syncDevice',
//         method: 'get',
//         params: params
//     })
// }

//获取设备信息
export function searchDeviceData(params) {
  return request({
    url: '/product/product/searchDeviceData',
    method: 'get',
    params: params
  })
}

export function syncProduct(params) {
  return request({
    url: '/product/product/syncProduct',
    method: 'get',
    params: params
  })
}

// 获取信息
export function getSearchInfoApi(id) {
  return request({
    url: `${url}/getSearchInfo/${id}`,
    method: 'get'
  })
}

// 验证产品号是否重复
export function validateProductNo(params) {
  return request({
    url: '/product/product/validateProductNo',
    method: 'get',
    params: params
  })
}

//根据色号模糊匹配lab值
export function queryRalNo(params) {
  return request({
    url: '/member/colorbook/queryRalNo',
    method: 'get',
    params: params
  })
}
//色号类别
export function queryColorType(params) {
  return request({
    url: '/member/colorbook/queryColorType',
    method: 'get',
    params: params
  })
}

export function colorcomparison(params) {
  return request({
    url: '/colorcompre/colorcompre/colorcomparison',
    method: 'get',
    params: params
  })
}

// 验证产品号是否重复
export function validateSampleProductNo(params) {
  return request({
    url: '/product/product/validateSampleProductNo',
    method: 'get',
    params: params
  })
}

// 导入生产产品
export function importExcel(params) {
  return request({
    url: '/product/product/importExcel',
    method: 'post',
    data: params
  })
}

export function importNumExcel(params) {
  return request({
    url: '/product/product/importNumExcel',
    headers: { 'Content-Type': 'application/json' },
    responseType: 'blob',
    method: 'post',
    data: params
  })
}

export function importDataColorExcel(params) {
  return request({
    url: '/product/product/importDataColorExcel',
    method: 'post',
    data: params,
    responseType: 'blob'
  })
}

//保存比色历史
export function saveHistory(params) {
  return request({
    url: '/member/colorcomprehistory/save',
    method: 'post',
    data: params
  })
}

//获取产品编号列表信息
export function selectProductNoList(params) {
  return request({
    url: '/product/product/selectProductNoList',
    method: 'get',
    params: params
  })
}

//获取配方信息
export function listAllCipe(params) {
  return request({
    url: '/product/product/listAllCipe',
    method: 'get',
    params: params
  })
}

//获取配方信息
export function listAllSCipe(params) {
  return request({
    url: '/product/product/listAllSCipe',
    method: 'get',
    params: params
  })
}
export function uploadQtxAndComputeLab(data) {
  return request({
    url: `${url}/uploadQtxAndComputeLab`,
    method: 'post',
    data
  })
}

export function parsingDataForQtx(data) {
  return request({
    url: `${url}/parsingDataForQtx`,
    method: 'post',
    data
  })
}

export function calculateTheReflectanceFromTheAngleOrLightSource(data) {
  return request({
    url: `${url}/calculateTheReflectanceFromTheAngleOrLightSource`,
    method: 'post',
    data
  })
}

export function hasInProgressUpload() {
  return request({
    url: `${url}/hasInProgressUpload`,
    method: 'get'
  })
}


export function uploadQtxInfoList(data) {
  return request({
    url: `${url}/saveUploadQtxDataList`,
    method: 'post',
    data
  })
}

export function getColorSpaceValueApi(data) {
  return request({
    url: `${url}/getColorSpaceValue`,
    method: 'post',
    data
  })
}

export function getColorimetricReport(data) {
  return request({
    url: `${url}/getColorimetricReport`,
    method: 'post',
    data
  })
}

export function exportColorimetricReportExcel(data) {
  return request({
    url: `${url}/exportColorimetricReportExcel`,
    method: 'post',
    data,
  })
}


export function getErpDataApi() {
  return request({
    url: `${url}/getErpData`,
    method: 'post',

  })
}