<template>
  <el-select
    v-model="selectedValue"
    :size="size"
    :style="{ width: width + 'px' }"
    @change="observerAngleChange"
  >
    <el-option
      v-for="item in observerAngleList"
      :key="item"
      :label="item"
      :value="item"
    ></el-option
  ></el-select>
</template>

<script>
import { getObserverAngleTextsApi } from "@/api/modules/colorbook";

export default {
  props: ["observerAngle", "size", "width"],
  data() {
    return {
      selectedValue: "",
      observerAngleList: [],
    };
  },
  mounted() {
    this.getObserverAngleTexts();
    this.selectedValue = this.observerAngle;
  },
  methods: {
    // 获取观察者数据
    getObserverAngleTexts() {
      getObserverAngleTextsApi().then((records) => {
        if (records.length > 0) {
          this.observerAngleList = records;
          if (records.includes("10 deg")) {
            this.selectedValue = "10 deg";
          } else {
            this.selectedValue = records[0];
          }
          this.observerAngleChange();
        } else {
          this.observerAngleList = [];
        }
      });
    },
    observerAngleChange() {
      this.$emit("change", this.selectedValue);
    },
  },
};
</script>