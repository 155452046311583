<template>
  <div class="app-product-container">
    <div class="main">
      <div class="c_top" ref="c_top">
        <div class="c_t_radiocontent">
          <!-- 查询方式tabs -->
          <el-radio-group v-model="searchType" @change="searchTypeChange">
            <el-radio-button v-if="isAuth('qtx_search')" label="4">
              <template v-if="searchType === 4"><img src="@/assets/qtx2.png" /></template>
              <template v-else><img src="@/assets/qtx1.png" /></template>
              <span>{{ $t("search.accurateColorSearch.qtxSearch") }}</span>
            </el-radio-button>
            <el-radio-button v-if="isAuth('elab_search')" label="1">
              <template v-if="searchType === 1"><img src="@/assets/2_active.png" /></template>
              <template v-else><img src="@/assets/2.png" /></template>
              <span>{{
                $t("search.accurateColorSearch.electronicColors")
              }}</span>
            </el-radio-button>
            <el-radio-button v-if="isAuth('space_search')" label="2">
              <template v-if="searchType === 2"><img src="@/assets/3_active.png" /></template>
              <template v-else><img src="@/assets/3.png" /></template>
              <span>{{ $t("search.accurateColorSearch.colorSpace") }}</span>
            </el-radio-button>
          </el-radio-group>
        </div>
        <div class="c_t_title_hint">
          {{ $t(searchTitle) }}
        </div>

        <div class="c_t_inputcontent">
          <div class="qtx_column_0" v-if="searchType == '4'">
            <!-- 上传QTX包 -->
            <el-upload class="qtx_upload_4" drag action="#" :on-remove="handleRemove" :file-list="fileList"
              :before-upload="beforeUpload" :on-change="clearFileChange" multiple :http-request="uploadQTXFiles"
              v-show="searchType == '4'">
              <div>
                <i class="el-icon-upload"></i>
                <div class="el-upload__tip_4" slot="tip">
                  {{ $t("common.upload.qtx.dragOrClickToUploadQtx")
                  }}<em style="color: blueviolet"></em>
                </div>
                <div class="el-upload__tip" slot="tip">
                  {{ $t("common.upload.qtx.onlyQTXSupportedMaxSize") }}
                </div>
              </div>
            </el-upload>
          </div>

          <div class="qtx_column_1" v-if="searchType == '4' && reflectanceList.length > 0"
            :style="{ '--scrollbar-track-color': dynamicBackgroundColor }">
            <div class="qtx_column_1_0" v-for="(item, index) in reflectanceList" :key="index">
              <span class="qtx_column_name" @click="assignLab(item)" :style="{
                fontWeight:
                  activeCurve && activeCurve.name == item.name ? 'bold' : '',
                color:
                  activeCurve && activeCurve.name == item.name
                    ? '#1F22D9'
                    : undefined,
              }">{{ item.name }}</span>
              <div class="qtx_column_1_0_0" :style="{ background: item.qtxHex }" @click="assignLab(item)"></div>
            </div>
          </div>

          <div class="qtx_column_2" v-if="displayChart || searchType == '2' || searchType == '1'">
            <!-- rgb -->
            <div class="c_t_i_left">
              <div class="c_t_i_l_top" v-if="searchType != '4'">
                <!-- TGB，LCH，LAB色彩数值 -->
                <div class="choose-color-body" v-if="searchType === 2">
                  <div class="left">
                    <div class="color-item" @click="handlerActiveTabs(1)">
                      <div class="title" :class="{ activeColorTab: currenIndex === 1 }">
                        sRGB
                      </div>
                    </div>
                    <div class="color-item" @click="handlerActiveTabs(2)">
                      <div class="title" :class="{ activeColorTab: currenIndex === 2 }">
                        LCH
                      </div>
                    </div>
                    <div class="color-item" @click="handlerActiveTabs(3)">
                      <div class="title" :class="{ activeColorTab: currenIndex === 3 }">
                        LAB
                      </div>
                    </div>
                  </div>

                  <div class="right-content">
                    <template v-if="currenIndex === 1">
                      <div class="item">
                        <span>R</span>
                        <el-input-number v-model="RGBrvalue" controls-position="right" @change="handleChange" :min="0"
                          :max="255"></el-input-number>
                      </div>
                      <div class="item">
                        <span>G</span>
                        <el-input-number v-model="RGBgvalue" controls-position="right" @change="handleChange" :min="0"
                          :max="255"></el-input-number>
                      </div>
                      <div class="item">
                        <span>B</span>
                        <el-input-number v-model="RGBbvalue" controls-position="right" @change="handleChange" :min="0"
                          :max="255"></el-input-number>
                      </div>
                    </template>
                    <template v-if="currenIndex === 2">
                      <div class="item">
                        <span>L</span>
                        <el-input-number v-model="LCHlvalue" controls-position="right" @change="handleChange" :min="0"
                          :max="100"></el-input-number>
                      </div>
                      <div class="item">
                        <span>C</span>
                        <el-input-number v-model="LCHcvalue" controls-position="right" @change="handleChange" :min="0"
                          :max="181"></el-input-number>
                      </div>
                      <div class="item">
                        <span>H</span>
                        <el-input-number v-model="LCHhvalue" controls-position="right" @change="handleChange" :min="0"
                          :max="360"></el-input-number>
                      </div>
                    </template>
                    <template v-if="currenIndex === 3">
                      <div class="item">
                        <span>L</span>
                        <el-input-number v-model="Lvaule" controls-position="right" @change="handleChange" :min="0"
                          :max="100"></el-input-number>
                      </div>
                      <div class="item">
                        <span>A</span>
                        <el-input-number v-model="Avaule" controls-position="right" @change="handleChange" :min="-128"
                          :max="127"></el-input-number>
                      </div>
                      <div class="item">
                        <span>B</span>
                        <el-input-number v-model="Bvaule" controls-position="right" @change="handleChange" :min="-128"
                          :max="127"></el-input-number>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <!-- 选择色卡 -->
              <div class="color-sort select-left" v-if="searchType === 1">
                <div class="color-code-select">
                  <div class="color-code-label">
                    {{ $t("electronicColors.common.category") }}
                  </div>
                  <el-select v-model="selectedCategory" size="medium" :placeholder="$t('search.accurateColorSearch.filter.chooseECategory')
                    " clearable @change="fetchColorList" style="width: 400px; margin-left: 10px">
                    <el-option v-for="item in colorBookCategoryList" :key="item.id" :label="item.categoryName"
                      :value="item.id" style="
                        font-size: 24px;
                        height: 50px;
                        padding: 8px;
                        padding-left: 12px;
                      "></el-option>
                  </el-select>
                </div>

                <div class="color-code-select">
                  <div class="color-code-label">
                    {{ $t("electronicColors.common.colorCode") }}
                  </div>
                  <el-autocomplete @select="handleSelect" v-model="colorNo" :fetch-suggestions="selectInputSearch"
                    @focus="handleFocus" :placeholder="$t('search.accurateColorSearch.filter.enterELab')
                      " style="width: 400px">
                    <template slot-scope="{ item }">
                      <div class="select-input-item" :title="item.colorNo">
                        <div class="color-chunk" :style="{ background: item.hex }"></div>
                        <div class="color-name">{{ item.colorNo }}</div>
                      </div>
                      <div v-if="item.last">
                        Please enter a keyword to filter more results.
                      </div>
                    </template>
                  </el-autocomplete>
                </div>
              </div>
            </div>
            <!-- 显示色块 -->
            <div class="color-chunk-box" v-if="displayChart || searchType == 2 || colorNo != ''">
              <div class="qtx_color_div_4" :style="{ backgroundColor: qtxHex }"></div>
              <!-- 颜色选择器组件 -->
              <el-color-picker class="color" style="margin-left: 10px" @active-change="getcolor" v-model="color1"
                v-if="searchType === 2"></el-color-picker>
            </div>
          </div>

          <div class="qtx_column_3" v-show="searchType != '2' && displayChart">
            <!-- 增加曲线图 -->
            <span id="activeDiscount" class="echarts-chart_4" v-show="searchType != '2'"></span>
          </div>
        </div>
      </div>

      <!-- 查询条件区域 -->
      <div class="c_main" style="border: none; margin-top: 20px">
        <div class="search-data">
          <el-checkbox-group v-model="productType" class="checkbox-group" @change="handlerGropChange">
            <el-checkbox label="0D" disabled>0D Color</el-checkbox>
            <el-checkbox label="1D" @change="handleYarnChange">1D Yarn</el-checkbox>
            <el-checkbox label="2D" @change="handleFabricChange">2D Fabrics and Trims</el-checkbox>
          </el-checkbox-group>
          <el-form :inline="true" size="mini" :model="dataForm" ref="dataForm" class="filter-form">
            <!-- 隐藏功能 -->
            <el-row>
              <div v-if="showFirstTab" class="search-1d">
                <el-col :span="3" class="filter-type">
                  <div class="oned-bar"></div>
                  <div class="search-1d-text">1D</div>
                </el-col>
                <div class="search-1d-content">
                  <!-- 纱线形态 -->

                  <el-col :span="5">
                    <el-form-item class="yarn-pattern">
                      <div slot="label" class="customLabel">
                        {{ $t("attributes.yarnType") }}
                      </div>
                      <el-select v-model="yarnType" clearable filterable remote multiple reserve-keyword
                        :placeholder="$t('attributes.yarnType')" :remote-method="selectYarnPatternList"
                        :loading="loading" @change="selectYarnChange" class="filter-property-select">
                        <el-option v-for="item in yarnTypeList" :key="item.attrValueCode" :label="item.attrValueName"
                          :value="item.attrValueCode"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <!-- 纱线规格 -->

                  <el-col :span="5">
                    <el-form-item class="yarn-specification">
                      <div slot="label" class="customLabel">
                        {{ $t("attributes.prdSpec") }}
                      </div>
                      <el-select v-model="yarnSpecification" clearable filterable multiple remote reserve-keyword
                        :placeholder="$t('attributes.prdSpec')" :remote-method="selectYarnSpecificationList"
                        :loading="loading" @change="selectYarnSpecificationChange" class="filter-property-select">
                        <el-option v-for="item in yarnSpecificationList" :key="item.attrValueCode"
                          :label="item.attrValueName" :value="item.attrValueCode"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <!-- 成份 -->

                  <el-col :span="5">
                    <el-form-item class="composition-one">
                      <div slot="label" class="customLabel">
                        {{ $t("attributes.fullNameIn") }}
                      </div>
                      <el-select v-model="composition" clearable filterable allow-create remote reserve-keyword
                        :placeholder="$t('attributes.fullNameIn')" :remote-method="selectCompositionList"
                        :loading="loading" class="filter-property-select">
                        <el-option v-for="item in compositionList" :key="item.attrValueCode" :label="item.attrValueName"
                          :value="item.attrValueCode"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>

                  <el-col :span="5">
                    <el-form-item>
                      <div slot="label" class="customLabel">
                        {{ $t("attributes.colorMatchType") }}
                      </div>
                      <el-select v-model="colorMatchType" clearable filterable remote reserve-keyword
                        :placeholder="$t('attributes.colorMatchType')" :remote-method="selectColorMatchTypeList"
                        :loading="loading" @change="selectColorMatchTypeChange" class="filter-property-select">
                        <el-option v-for="item in colorMatchTypeList" :key="item.attrValueCode"
                          :label="item.attrValueName" :value="item.attrValueCode"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </div>
              </div>
            </el-row>

            <!-- 隐藏功能 -->
            <el-row>
              <div v-if="showSecondTab" class="search-2d">
                <el-col :span="3" class="filter-type">
                  <div class="oned-bar"></div>
                  <div class="search-1d-text" style="">2D</div>
                </el-col>
                <div class="search-1d-content">
                  <el-col :span="5">
                    <el-form-item class="production-process">
                      <div slot="label" class="customLabel">
                        {{ $t("attributes.productionProcess") }}
                      </div>
                      <el-select v-model="productionProcess" clearable filterable remote reserve-keyword
                        :placeholder="$t('attributes.productionProcess')" :remote-method="selectProductionProcessList"
                        :loading="loading" @change="selectProductionProcessChange" class="filter-property-select">
                        <el-option v-for="item in productionProcessList" :key="item.attrValueCode"
                          :label="item.attrValueName" :value="item.attrValueCode"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>

                  <el-col :span="5">
                    <el-form-item class="composition-two">
                      <div slot="label" class="customLabel">
                        {{ $t("attributes.fullNameIn") }}
                      </div>
                      <el-select v-model="composition" clearable filterable allow-create remote reserve-keyword
                        :placeholder="$t('attributes.fullNameIn')" :remote-method="selectCompositionList"
                        :loading="loading" @change="selectCompositionChange" class="filter-property-select">
                        <el-option v-for="item in compositionList" :key="item.attrValueCode" :label="item.attrValueName"
                          :value="item.attrValueCode"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </div>
              </div>
            </el-row>
            <!-- v-if="isAuth('search:advanced')"  -->
            <!-- 隐藏功能 -->
            <el-row>
              <div v-if="isAuth('advancedSearch')" class="search-edye">
                <el-col :span="3" class="filter-type">
                  <div class="oned-bar"></div>
                  <div class="search-1d-text">
                    {{ $t("search.accurateColorSearch.filter.advanced") }}
                  </div>
                </el-col>
                <div class="search-1d-content">
                  <el-col :span="5">
                    <el-form-item class="color-sample-number filter-property">
                      <div slot="label" class="customLabel">
                        {{ $t("attributes.colorMatchNumber") }}
                      </div>
                      <el-input v-model="colorMatchNumber" :placeholder="$t('attributes.colorMatchNumber')"
                        class="color-sample-number-input filter-property"></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- // 色样名称 -->
                  <el-col :span="5">
                    <el-form-item class="color-sample-number">
                      <div slot="label" class="customLabel">
                        {{ $t("physicalColors.attributeValues.name") }}
                      </div>
                      <el-input v-model="colorName" :placeholder="$t('physicalColors.attributeValues.name')"
                        class="color-sample-number-input filter-property"></el-input>
                    </el-form-item>
                  </el-col>

                  <el-col :span="5">
                    <el-form-item class="has-stock">
                      <div slot="label" class="customLabel">
                        {{ $t("common.colorDetails.inventory") }}
                      </div>
                      <el-select v-model="hasStock" filterable reserve-keyword
                        :placeholder="$t('common.colorDetails.inventory')" class="filter-property-select">
                        <el-option :label="$t('comon.colorDetails.inventory.all')" value="-1"></el-option>
                        <el-option :label="$t('common.colorDetails.inventory.inStock')" value="1"></el-option>
                        <el-option :label="$t('common.colorDetails.inventory.outOfStock')
                          " value="0"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </div>
              </div>
            </el-row>
            <el-row>
              <div class="search-search-color" style="margin-bottom: 30px">
                <el-col :span="3" class="filter-type">
                  <div class="oned-bar"></div>
                  <div class="search-1d-text">
                    {{ $t("search.accurateColorSearch.filter.color") }}
                  </div>
                </el-col>
                <div class="search-1d-content">
                  <el-col :span="5">
                    <el-form-item class="search-observerAngle" :rules="[
                      {
                        message: $t('common.color.observerAngle'),
                        trigger: 'blur',
                      },
                    ]">
                      <div slot="label" class="customLabel">
                        {{ $t("common.color.observerAngle") }}
                      </div>
                      <el-select v-model="observerAngle" :placeholder="$t('common.color.observerAngle')"
                        @change="observerAngleChange" class="filter-property-select">
                        <el-option v-for="item in observerAngleList" :key="item" :label="item"
                          :value="item"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>

                  <el-col :span="5">
                    <el-form-item :rules="[
                      {
                        message: $t('common.color.illuminant'),
                        trigger: 'blur',
                      },
                    ]" class="search-illuminant">
                      <div slot="label" class="customLabel">
                        {{ $t("common.color.illuminant") }}
                      </div>
                      <el-select v-model="illuminant" :placeholder="$t('common.color.illuminant')"
                        @change="illuminantChange" class="filter-property-select">
                        <el-option v-for="item in illuminantList" :key="item" :label="item" :value="item"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>

                  <el-col :span="5">
                    <el-form-item :rules="[
                      {
                        message: $t('common.color.colorDifferenceFormula'),
                        trigger: 'blur',
                      },
                    ]">
                      <div slot="label" class="customLabel">
                        {{ $t("common.color.colorDifferenceFormula") }}
                      </div>
                      <el-select v-model="colorDifferenceFormula"
                        :placeholder="$t('common.color.colorDifferenceFormula')" class="filter-property-select">
                        <el-option label="CIE2000(2:1:1)" value="-1"></el-option>
                        <el-option label="CIE1994" value="2"></el-option>
                        <el-option label="CIE1976" value="0"></el-option>
                        <el-option label="CMC(2:1)" value="1"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>

                  <el-col :span="5">
                    <el-form-item>
                      <div slot="label" class="customLabel">
                        {{ $t("searchDiffRange.accurateColorSearch.filter.color") }}
                      </div>
                      <el-input-number v-model="diffRange" :precision="1" :step="0.5" :min="0.1" :max="10.0"
                        class="filter-property-num"></el-input-number>
                    </el-form-item>
                  </el-col>
                </div>
              </div>
            </el-row>
          </el-form>
        </div>

        <div style="width: 100%; text-align: center">
          <el-button class="btn" type="primary" style="
              width: 350px;
              box-shadow: 0px 3px 10px rgba(15, 16, 96, 0.41);
            " icon="el-icon-search" @click="searchProductList">{{ $t("common.buttons.search") }}</el-button>
          <el-button @click="reset('dataForm')" style="width: 100px" size="small">{{ $t("common.buttons.reset")
            }}</el-button>
        </div>
      </div>
    </div>
    <div v-if="showSearch" class="divTab">
      <div class="tab-header">
        <div class="query-label">
          {{ $t("search.accurateColorSearch.results") }}
        </div>
        <el-tabs type="card" v-model="tabsActiveName" @tab-click="handleClick" class="tab-pane">
          <!-- 标准色号 e-lab-->
          <el-tab-pane :label="$t('search.accurateColorSearch.results.electronicColors')" name="second"></el-tab-pane>
          <!-- 色样 p-lab-->
          <el-tab-pane :label="$t('search.accurateColorSearch.results.physicalColors')" name="first"></el-tab-pane>
        </el-tabs>
        <el-tooltip class="item" effect="dark" :content="$t(center)" placement="right">
          <el-button v-if="isAuth('color_difference')" type="warning" class="color-report"
            @click="colorDifferenceReport(3)" size="small" :disabled="compareDisabled">{{
              $t("search.accurateColorSearch.compareColors.title")
            }}</el-button>
        </el-tooltip>
      </div>

      <div class="color-swatch-checkboxes" :style="colorSwatchStyle" v-show="activeName == 'first'">
        <el-checkbox-group v-model="colorSwatchCheckList" @change="handlerColorSwatchCheck">
          <template v-for="(item, index) in colorSwatchCheckValuekList">
            <el-checkbox :label="item.value" :key="index">{{
              item.name
            }}</el-checkbox>
          </template>
        </el-checkbox-group>
      </div>
      <div class="color-swatch-checkboxes" :style="colorSwatchStyle" v-show="activeName == 'second'">
        <el-checkbox-group v-model="standardColorCheckList" @change="standardColorCheckListChange">
          <template v-for="(item, index) in standardColorNumberValueList">
            <el-checkbox :label="item.value" :key="index">{{
              item.name
            }}</el-checkbox>
          </template>
        </el-checkbox-group>
      </div>
    </div>

    <div v-if="showSearch" class="content">
      <div class="c_m_cards" v-loading="dataListLoading">
        <!-- -----------------------------------------别表数据部分------------------------------------------------------------- -->
        <el-card :v-if="dataList.length > 0" :body-style="{ padding: '0px' }" v-for="(item, index) in dataList"
          :key="item.id" @mouseenter.native="enter(item.id)" @mouseleave.native="leave()" @click="cardCLick">
          <div class="box">
            <div class="chechbox" v-if="r && r.length > 0">
              <el-checkbox v-model="item.checked" @change="chechboxClick(item)"></el-checkbox>
            </div>
            <div v-if="item.hex || item.Hex" :style="{ backgroundColor: item.hex || item.Hex }" class="c_m_c_img_">
              <img v-if="getHexColor(item.hex) != '#fff' && item.hasStock === '1'" class="inventory-img"
                src="@/assets/inventory.png" />
              <img v-if="getHexColor(item.hex) === '#fff' && item.hasStock === '1'" class="inventory-img"
                src="@/assets/inventory_white.png" />
            </div>
            <div class="c_m_c_bot">
              <span class="c_m_c_b_tit">{{
                !item.colorSampleName ? "-" : item.colorSampleName
              }}</span>
              <div class="c_m_c_b_txt">
                <p class="spanSize" v-if="item.tabsActiveName == 'first'">
                  <span class="spanSize">
                    {{ $t("common.colorDetails.basic.status") }}
                  </span>
                  {{
                    item.state == "0"
                      ? $t("common.colorDetails.basic.productionSample")
                      : $t("common.colorDetails.basic.labSample")
                  }}
                </p>
                <p class="spanSize" v-if="item.tabsActiveName == 'first'">
                  <span class="spanSize">{{ $t("attributes.yarnType") }}</span>
                  {{ item.yarnType == "" ? "-" : item.yarnType }}
                </p>
                <p class="spanSize" v-if="item.tabsActiveName == 'first'">
                  <span class="spanSize">{{ $t("attributes.prdSpec") }}</span>
                  {{
                    item.yarnSpecification == "" ? "-" : item.yarnSpecification
                  }}
                </p>
                <p class="spanSize" v-if="item.tabsActiveName == 'first'">
                  <span class="spanSize">{{
                    $t("attributes.fullNameIn")
                  }}</span>
                  {{ item.composition == "" ? "-" : item.composition }}
                </p>
                <p class="spanSize" v-if="item.tabsActiveName == 'second'">
                  <span class="spanSize">{{
                    $t("menu.electronicColors.supplierBrands")
                  }}</span>
                  {{ item.brandName == "" ? "-" : item.brandName }}
                </p>
                <p class="spanSize" v-if="item.tabsActiveName == 'second'">
                  <span class="spanSize">{{
                    $t("attributes.ColorCodeClassification")
                  }}</span>
                  {{ item.categoryName == "" ? "-" : item.categoryName }}
                </p>
                <p class="spanSize">
                  <span class="spanSize">{{
                    $t("common.color.colorDifferenceShort")
                  }}</span>
                  {{ item.e00Value == "" ? "-" : item.e00Value }}
                </p>
                <p class="spanSize" v-if="
                  item.tabsActiveName == 'first' &&
                  (smart === '0' || smart === '1')
                ">
                  <span class="spanSize">Smart color</span>
                  {{ item.samrt == "0" ? "Smart color" : "Smart Fabric" }}
                </p>
              </div>
            </div>
            <div class="layout-mask" v-show="activeId === item.id">
              <div class="main-mask">
                <el-button type="primary" size="small" @click="cpdetailsHandle(item)" style="">{{
                  $t("common.colorDetails.title") }}</el-button>
              </div>
            </div>
          </div>
        </el-card>
        <p v-if="dataList.length == 0 && nodata" style="
            margin: 10px auto;
            font-size: 14px;
            color: #999;
            display: flex;
            align-items: center;
          ">
          {{ $t("search.accurateColorSearch.results.noData") }}
        </p>
      </div>
      <!-- <div id="fenye"> -->
      <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
        :page-sizes="[8, 16, 32, 64]" :page-size="pageSize" :total="totalPage"
        :prev-text="$t('pagination.previousPage')" :next-text="$t('pagination.nextPage')"
        layout="total, sizes, prev, pager, next, jumper"></el-pagination>
      <!-- </div> -->
    </div>
    <update-history v-if="updateHistoryVisible" ref="updateHistory" @refreshDataList="historyOpt = 2"></update-history>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <cpdetails v-if="cpdetailsVisible" ref="cpdetails"></cpdetails>
    <sampleDetails v-if="sampleDetailsVisible" ref="sampleDetails"></sampleDetails>
    <cdreport v-if="cdreportVisible" ref="cdreport"></cdreport>
    <update-password ref="updatePassword"></update-password>
    <!-- 底部logo -->
    <!-- <Footer /> -->
  </div>
</template>

<script>
import * as echarts from "echarts";
import i18n from "@/i18n";
import resize from "./resize";
import Cookies from "js-cookie";
import {
  searchListApi,
  getLABApi,
  gethex,
  uploadQtxAndComputeLab,
  calculateTheReflectanceFromTheAngleOrLightSource,
  getColorSpaceValueApi,
} from "@/api/modules/product";
import { queryColorCars, queryColorSampleApi } from "@/api/modules/colorbook";
import {
  colorBookCategoryListApi,
  getColorBookCategoryForSearchApi,
} from "@/api/modules/colorbookcategory";
import AddOrUpdate from "./add-or-update";
import cpdetails from "./cpdetails";
import sampleDetails from "./sampleDetails";
import { firstLoginCheckerLoginApi } from "@/api/login";
import UpdateHistory from "../history/add-or-update";
import cdreport from "./color-difference-report";
import {
  getIlluminantTextsApi,
  getObserverAngleTextsApi,
} from "@/api/modules/colorbook";
import { queryAttrValueBykeyApi } from "@/api/modules/attrValue";
import { getUserInfoApi } from "@/api/modules/user";

import colorCompareStore from "@/store/modules/colorCompareStore";
import UpdatePassword from "../../login/components/updatepassword";
import {
  getUserInfoLocation,
  setUserInfoLocation,
  removeUserInfoLocation,
} from "@/utils/auth";
export default {
  mixins: [resize],
  data() {
    return {
      searchTitle: this.$t("search.accurateColorSearch.qtxSearch.prompt"),
      displayChart: false,
      imgDisabledWhite: false,
      imgDisabled: false,
      center: "",
      updatePasswordVisible: true,
      showSearch: false,
      idFlag: "1",
      yarnType: [],
      yarnTypeList: [],
      yarnSpecification: [],
      composition: "",
      compositionList: [],
      colorSampleShape: "",
      colorSampleShapeList: [],
      productionProcess: "",
      productionProcessList: [],
      colorMatchNumber: "",
      colorName: "",
      diffRange: 5.0,
      colorDifferenceFormula: "-1",
      colorDifferenceFormulaList: [],
      hasStock: "-1",
      yarnSpecificationList: [],
      tabPosition: "left",
      activeTab: "first",
      showFirstTab: false, // 是否展示1D标签页
      showSecondTab: false, // 是否展示2D标签页
      RGBrvalue: 0,
      RGBgvalue: 0,
      RGBbvalue: 0,

      LCHlvalue: 0,
      LCHcvalue: 0,
      LCHhvalue: 0,
      productType: ["0D"],
      colorSwatchCheckList: [0, 1],
      tabsActiveFlag: false,
      colorSwatchCheckValuekList: [
        {
          name: this.$t("menu.physicalColors.labSamples"),
          value: 1,
        },
        {
          name: this.$t("menu.physicalColors.productionSamples"),
          value: 0,
        },
      ],

      standardColorNumberValueList: [],
      smart: "",
      standardColorCheckList: [],
      activeName: "first",
      tabsActiveName: "second",
      currenIndex: 1,
      fileList: [],
      qtxHex: "#fff",
      dynamicBackgroundColor: "#f2f2f2", // 默认的滚动条轨道背景颜色
      // qtxName: "Blue Azul",
      reflectanceData: [
        2.3012, 2.7863, 3.1758, 3.5413, 4.787, 4.8768, 5.743, 6.5473, 8.4343,
        10.1072, 12.8534, 14.6305, 15.1472, 16.5313, 17.9447, 18.7481, 19.516,
        20.3826, 21.2437, 22.0256, 21.999, 20.9818, 19.9285, 18.2854, 17.7829,
        16.488, 15.2828, 14.2124, 13.1924, 12.2846, 11.391, 10.4009, 9.338,
        8.378, 7.3414,
      ],
      treflectanceData: [],
      refLInterval: 10,
      refLLow: 360,
      refLPoints: null,
      reflectanceList: [],
      r: "",
      updateHistoryVisible: false,
      cookie: {},
      sessiondataList: [],
      searchForm: [],
      searchData: {},
      websock: null,
      hsvStr: { h: 0, s: 0, v: 0 },
      isMaskShow: false,
      activeId: null,
      activeNameE00Type: "first",
      e00Type: "1", //色差类型（综合色差值和45度色差值）
      observerAngle: "10 deg",
      illuminant: "D65",
      loading: false,
      device: {
        id: "",
      },
      observerAngleList: [],
      illuminantList: [],
      dataForm: {
        searchType: "1",
        e00lab2: "",
        orderId: "",
        e00Value: "",
        id: "",
        picUrl: "",
        qrUrl: "",
        factoryId: "",
        brandId: "",
        labAve: "",
        state: "",
      },
      factoryList: [],
      // 色号分类列表
      selectedCategory: "",
      colorNo: "",
      colorBookCategoryList: [],
      dynamicColorList: [], // 动态加载的颜色列表
      dataList: [],
      selectedData: [],
      pageIndex: 1,
      pageSize: 8,
      totalPage: 0,
      dataListLoading: false,
      addOrUpdateVisible: false,
      Lvaule: 0,
      Avaule: 0,
      Bvaule: 0,
      type: "4",
      color1: "#FF0000",
      cpdetailsVisible: false,
      sampleDetailsVisible: false,
      cdreportVisible: false,
      isdisabled: false,
      nodata: false,
      searchType: 4,
      deviceType: "",
      colorNoType: "",
      searchNoType: "",
      historyOpt: 0,
      colorMatchType: "",
      groups: "",
    };
  },
  components: {
    AddOrUpdate,
    UpdateHistory,
    cpdetails,
    sampleDetails,
    cdreport,
    Footer: () => import("@/views/homePage/admin/components/Footer"),
    UpdatePassword,
  },
  computed: {
    devicedata() {
      return this.$store.state.user.devicedata;
    },
    colorSwatchStyle() {
      return {
        marginLeft: this.isAuth("color_difference") ? "-360px" : "-270px",
      };
    },
    compareDisabled() {
      // 必须有光谱数据（不支持“色差空间”
      // 必须勾选结果
      if (this.r && this.r.length > 0) {
        return this.dataList.filter((item) => item.checked) <= 0;
      } else {
        return true;
      }
    },
  },
  watch: {
    devicedata(val) {
      this.deviceType = val.id + val.readMode;
    },
  },
  created() {
    let id = this.$store.getters.userDevice.id;
    this.device.id = id;
    this.getColorBookCategoryList();
    this.dataForm.e00Value = "";
    this.dataList = [];
    this.totalPage = 0;
    this.nodata = true;
    this.productType = ["0D"];
    this.groups = this.productType.join(",");
    this.selectYarnPatternList();
    this.selectYarnSpecificationList();
    this.selectCompositionList();
    this.selectColorMatchTypeList();
    this.selectProductionProcessList();
    // 是否有库存下拉数据获取方法
    // this.selectHasStockList()
    this.getColorBookCategory();
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  mounted() {
    this.initDom();
    this.getSearchClick();
    this.initActiveDiscount();
    this.getObserverAngleTexts();

    this.firstLoginCheckerLogin();
    this.compareColors();
  },
  methods: {
    validateInput(value) {
      // Remove any non-numeric characters except for dot
      let sanitizedValue = value.replace(/[^0-9.]/g, "");

      // Allow only one dot and restrict to one decimal place
      if ((sanitizedValue.match(/\./g) || []).length > 1) {
        sanitizedValue = sanitizedValue.slice(
          0,
          sanitizedValue.lastIndexOf(".")
        );
      }

      // Ensure the value is a valid number
      let numericValue = parseFloat(sanitizedValue);

      // If the value is not a number or greater than 10, reset to empty
      if (isNaN(numericValue) || numericValue > 10) {
        this.diffRange = "";
      } else {
        // Restrict to one decimal place
        const parts = sanitizedValue.split(".");
        if (parts.length > 1 && parts[1].length > 1) {
          sanitizedValue = `${parts[0]}.${parts[1].slice(0, 1)}`;
        }
        this.diffRange = sanitizedValue;
      }
    },
    compareColors() {
      if (!this.dataList && this.dataList.length < 0) {
        this.center = this.$t(
          "search.accurateColorSearch.results.compareColorsMustSearch"
        );
      } else if (this.searchType === 2) {
        this.center = this.$t(
          "search.accurateColorSearch.results.compareColorsNoColorSpace"
        );
      } else if (
        this.r &&
        this.r.length > 0 &&
        this.dataList.filter((item) => item.checked) <= 0
      ) {
        this.center = this.$t(
          "search.accurateColorSearch.results.compareColorsMustSelect"
        );
      } else {
        this.center = this.$t(
          "search.accurateColorSearch.results.compareColorsTip"
        );
      }
    },
    firstLoginCheckerLogin() {
      getUserInfoApi().then((response) => {
        let loginForm = {
          userName: response.userName,
          userPwd: response.userPwd,
        };
        if (response) {
          setUserInfoLocation(JSON.stringify(response));
          firstLoginCheckerLoginApi(loginForm).then((res) => {
            if (res.firstLogin === "1") {
              // if (.userInfo.id) {
              this.$refs["updatePassword"].init(response);
              // }
            }
          });
        }
      });
    },

    replaceNamesInCheckList() {
      this.standardColorCheckList = this.standardColorNumberValueList.map(
        (color) => color.name
      );
    },

    getColorBookCategory() {
      getColorBookCategoryForSearchApi().then((res) => {
        this.standardColorNumberValueList = res;
        this.replaceNamesInCheckList();
      });
    },
    handlerGropChange(checked) {
      this.groups = checked.join(",");
      if (this.tabsActiveFlag) {
        this.searchProductList();
      }
    },
    change(e) {
      console.log(e);
    },
    handlerColorSwatchCheck(check) {
      this.getDataList();
    },
    standardColorCheckListChange() {
      this.queryColorSampleApi();
    },
    handleYarnChange(checked) {
      if (checked) {
        this.tabsActiveName = "first";
      } else {
        this.tabsActiveName = "second";
      }

      this.showFirstTab = checked;
      if (!checked) {
        this.yarnType = [];
        this.yarnSpecification = [];
        this.composition = "";
        this.colorMatchType = "";
      }
    },
    handleFabricChange(checked) {
      if (checked) {
        this.tabsActiveName = "first";
      } else {
        this.tabsActiveName = "second";
      }
      this.showSecondTab = checked;
      if (!checked) {
        this.composition = "";
        this.productionProcess = "";
      }
    },

    // 纱线形态
    selectYarnPatternList(query) {
      // 当输入框内容变化时，实时调用后台接口
      this.loading = true;
      const data = {
        searchValue: query,
        searchKey: "yarnType",
      };
      queryAttrValueBykeyApi(data)
        .then((res) => {
          this.yarnTypeList = res;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    selectYarnChange(value) {
      // 监控输入框清除操作
      if (!value) {
        this.selectYarnPatternList("");
      }
    },

    // 纱线规格
    selectYarnSpecificationList(query) {
      this.loading = true;
      const data = {
        searchValue: query,
        searchKey: "yarnSpecification",
      };
      queryAttrValueBykeyApi(data)
        .then((res) => {
          this.yarnSpecificationList = res;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    selectYarnSpecificationChange(value) {
      // 监控输入框清除操作
      if (!value) {
        this.selectYarnSpecificationList("");
      }
    },
    // 成份
    selectCompositionList(query) {
      this.loading = true;
      const data = {
        searchValue: query,
        searchKey: "composition",
      };
      queryAttrValueBykeyApi(data)
        .then((res) => {
          this.compositionList = res;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    selectCompositionChange(value) {
      // 监控输入框清除操作
      if (!value) {
        this.selectCompositionList("");
      }
    },
    // 打色样品形态
    selectColorMatchTypeList(query) {
      this.loading = true;
      const data = {
        searchValue: query,
        searchKey: "colorMatchType",
      };
      queryAttrValueBykeyApi(data)
        .then((res) => {
          this.colorMatchTypeList = res;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    selectColorMatchTypeChange(value) {
      // 监控输入框清除操作
      if (!value) {
        this.selectColorMatchTypeList("");
      }
    },
    //  生产工序
    selectProductionProcessList(query) {
      this.loading = true;
      const data = {
        searchValue: query,
        searchKey: "productionProcess",
      };
      queryAttrValueBykeyApi(data)
        .then((res) => {
          this.productionProcessList = res;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    selectProductionProcessChange(value) {
      // 监控输入框清除操作
      if (!value) {
        this.selectProductionProcessList("");
      }
    },
    //
    handleSelect(item) {
      this.fileList = [];
      this.reflectanceList = [];
      this.colorNo = item.colorNo;
      this.qtxHex = item.hex;
      this.r = item.r;
      this.reflInterval = item.reflInterval;
      this.reflLow = item.reflLow;
      this.reflPoints = item.reflPoints;
      this.Lvaule = item.l;
      this.Avaule = item.a;
      this.Bvaule = item.b;
      this.selectedCategory = item.categoryName;
      this.reflectanceData = item.reflectanceData;
      // this.qtxName = item.colorName;
      this.color1 = item.hex;

      this.RGBrvalue = item.rgbList[0];
      this.RGBgvalue = item.rgbList[1];
      this.RGBbvalue = item.rgbList[2];

      this.LCHlvalue = item.lchList[0];
      this.LCHcvalue = item.lchList[1];
      this.LCHhvalue = item.lchList[2];
      this.initActiveDiscount();
    },
    selectInputSearch(val, cb) {
      // 假设此处有一个API用于获取指定分类的颜色列表
      let result = [];
      let params = {
        colorNo: val,
        categoryId: this.categoryId,
        observerAngle: this.observerAngle,
        illuminant: this.illuminant,
      };
      queryColorCars(params).then((res) => {
        result = res;
        this.Lvaule = result.l;
        this.Avalue = result.a;
        this.Bvaule = result.b;
        this.hex = result.hex;
        if (result.length == 200) {
          result[result.length - 1].last = true;
        }
      });
      setTimeout(() => {
        cb(result);
      }, 300);
    },
    handleFocus() {
      this.selectInputSearch(this.colorNo, (results) => { });
    },

    fetchColorList(categoryId) {
      this.categoryId = categoryId;
    },
    // 大货样/标准色号tabs
    handleClick(tab, event) {
      // this.dataList.forEach(item => item.checked = false)
      this.tabsActiveName = tab.name;
      if (this.tabsActiveName == "second") {
        this.dataList = [],
          this.activeName = "second";
        this.queryColorSampleApi();
        //清空查询结构后勾选的查询条件
        // this.colorSwatchCheckList = []
      } else {
        this.dataList = [],
          this.activeName = "first";
        this.getDataList();
        //清空查询结构后勾选的查询条件
        // this.standardColorCheckList = []
      }
    },

    // RGB LCH LAB 值改变时change方法
    handleChange() {
      this.fileList = [];
      this.reflectanceList = [];
      this.selectedCategory = "";
      this.colorNo = "";
      let data = {};
      if (this.currenIndex === 1) {
        data = {
          colorSpaceType: "RGB",
          rgbList: [this.RGBrvalue, this.RGBgvalue, this.RGBbvalue],
        };
      } else if (this.currenIndex === 2) {
        data = {
          colorSpaceType: "LCH",
          colorSpaceList: [this.LCHlvalue, this.LCHcvalue, this.LCHhvalue],
        };
      } else {
        data = {
          colorSpaceType: "LAB",
          colorSpaceList: [this.Lvaule, this.Avaule, this.Bvaule],
        };
      }
      // 色彩空间数值转换接口
      getColorSpaceValueApi(data).then((res) => {
        this.RGBrvalue = res.rgblist[0];
        this.RGBgvalue = res.rgblist[1];
        this.RGBbvalue = res.rgblist[2];

        this.LCHlvalue = res.lchlist[0];
        this.LCHcvalue = res.lchlist[1];
        this.LCHhvalue = res.lchlist[2];

        this.Lvaule = res.lablist[0];
        this.Avaule = res.lablist[1];
        this.Bvaule = res.lablist[2];
        this.qtxHex = res.hex;
        this.color1 = res.hex;
      });
    },

    handlerActiveTabs(index) {
      this.currenIndex = index;
    },
    cardCLick() { },
    updateDynamicBackgroundColor(newColor) {
      this.dynamicBackgroundColor = newColor;
    },
    initActiveDiscount() {
      var activeDiscount = this.$echarts.init(
        document.getElementById("activeDiscount")
      );
      // 数据处理
      var reflectanceData = this.reflectanceData;
      var reflLow = this.refLLow;
      var reflInterval = this.refLInterval;
      if (this.idFlag === 1) {
        reflectanceData.forEach((element, index) => {
          reflectanceData[index] = (element / 100).toFixed(5);
        });
      }

      var wavelengths = [];
      for (
        var i = reflLow;
        i < reflLow + reflectanceData.length * reflInterval;
        i += reflInterval
      ) {
        wavelengths.push(i + "nm");
      }
      let option = null;
      // 曲线表配置
      option = {
        // title: {
        //   text: this.qtxName,
        //   left: "center",
        // },
        tooltip: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          textStyle: {
            color: "#ffffff",
          },
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        xAxis: {
          type: "category",
          name: this.$t("common.color.spectralGraph.wavelengthShort"),
          data: wavelengths,
        },
        yAxis: {
          type: "value",
          name: this.$t("common.color.spectralGraph.reflectance"),
        },
        series: [
          {
            data: reflectanceData,
            type: "line",
            smooth: true,
            name: "光谱反射率",
            itemStyle: {
              // 设置折线节点的样式
              normal: {
                color: function (params) {
                  // 根据节点值设置颜色
                  return params.data > 150 ? "red" : "#013e96";
                },
              },
            },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      if (option && typeof option === "object") {
        activeDiscount.setOption(option, true);
      }
    },
    // 文件列表移除文件时的钩子
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    beforeUpload(file) {
      if (!file) {
        this.$message.error("请选择要上传的文件！");
        return false; // 阻止文件上传
      }
      return true;
    },
    // 文件值改变时触发 change事件
    clearFileChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]];
      } else {
        this.fileList = fileList[0];
      }
    },
    // 文件上传时
    uploadQTXFiles(file, fileList) {
      let falg = "reveal";
      this.uploadQtx(file.file, falg);
    },
    uploadQtx(file, falg) {
      // 请求接口
      this.file = file;
      this.formData = new FormData();
      this.formData.append("file", file);
      this.formData.append("observerAngle", this.observerAngle);
      this.formData.append("illuminant", this.illuminant);
      uploadQtxAndComputeLab(this.formData).then((res) => {
        if (res) {
          this.color1 = res.qtxVo.qtxHex;
          this.activeCurve = res.qtxVo;
          this.Lvaule = res.qtxVo.lvalue;
          this.Avaule = res.qtxVo.avalue;
          this.Bvaule = res.qtxVo.bvalue;
          this.qtxHex = res.qtxVo.qtxHex;
          this.dynamicBackgroundColor = res.qtxVo.qtxHex;
          this.RGBrvalue = res.qtxVo.rgbList[0];
          this.RGBgvalue = res.qtxVo.rgbList[1];
          this.RGBbvalue = res.qtxVo.rgbList[2];

          this.LCHlvalue = res.qtxVo.lchList[0];
          this.LCHcvalue = res.qtxVo.lchList[1];
          this.LCHhvalue = res.qtxVo.lchList[2];

          this.qtxName = res.qtxVo.name;
          this.refLInterval = res.qtxVo.refLInterval;
          this.refLLow = res.qtxVo.refLLow;
          this.refLPoints = res.qtxVo.refLPoints;
          this.reflectanceData = res.qtxVo.reflectanceData;
          this.r = res.qtxVo.r;
          this.reflectanceList = res.qtxInfoList;
          this.initActiveDiscount();
          this.displayChart = true;
          // 清除标准号条件
          this.colorNo = "";
          this.selectedCategory = "";
        } else {
          // 错误信息
          this.$message({
            message: "解析数据为空",
            type: "error",
          });
        }
      });
    },

    // 获取光源数据
    getSearchClick() {
      getIlluminantTextsApi().then((records) => {
        if (records && records.length > 0) {
          this.illuminantList = records;
        } else {
          this.illuminantList = [];
        }
      });
    },
    // 获取观察者数据
    getObserverAngleTexts() {
      getObserverAngleTextsApi().then((records) => {
        if (records && records.length > 0) {
          this.observerAngleList = records;
        } else {
          this.observerAngleList = [];
        }
      });
    },
    // 改变观察者角度
    observerAngleChange() {
      let params = {
        r: this.r,
        refLInterval: this.refLInterval,
        refLLow: this.refLLow,
        refLPoints: this.refLPoints,
        observerAngle: this.observerAngle,
        illuminant: this.illuminant,
      };
      if (this.searchType != "2") {
        calculateTheReflectanceFromTheAngleOrLightSource(params).then((res) => {
          if (res) {
            this.Lvaule = res.qtxVo.lvalue;
            this.Avaule = res.qtxVo.avalue;
            this.Bvaule = res.qtxVo.bvalue;

            (this.RGBrvalue = res.qtxVo.rgbrValue),
              (this.RGBgvalue = res.qtxVo.rgbgValue),
              (this.RGBbvalue = res.qtxVo.rgbbValue),
              (this.LCHlvalue = res.qtxVo.lchlValue),
              (this.LCHcvalue = res.qtxVo.lchcValue),
              (this.LCHhvalue = res.qtxVo.lchhValue),
              (this.qtxHex = res.qtxVo.qtxHex);
            this.reflectanceData = res.qtxVo.reflectanceData;
          }
        });
      }

    },
    //改变光源
    illuminantChange() {
      let params = {
        r: this.r,
        refLInterval: this.refLInterval,
        refLLow: this.refLLow,
        refLPoints: this.refLPoints,
        observerAngle: this.observerAngle,
        illuminant: this.illuminant,
      };
      if (this.searchType != "2") {
        calculateTheReflectanceFromTheAngleOrLightSource(params).then((res) => {
          if (res) {
            this.Lvaule = res.qtxVo.lvalue;
            this.Avaule = res.qtxVo.avalue;
            this.Bvaule = res.qtxVo.bvalue;

            (this.RGBrvalue = res.qtxVo.rgbrValue),
              (this.RGBgvalue = res.qtxVo.rgbgValue),
              (this.RGBbvalue = res.qtxVo.rgbbValue),
              (this.LCHlvalue = res.qtxVo.lchlValue),
              (this.LCHcvalue = res.qtxVo.lchcValue),
              (this.LCHhvalue = res.qtxVo.lchhValue),
              (this.qtxHex = res.qtxVo.qtxHex);
            this.reflectanceData = res.qtxVo.reflectanceData;
          }
        });
      }

    },
    // 点击上传的QTX包数据列表时出发事件
    assignLab(item) {
      this.activeCurve = item;
      this.Lvaule = item.lvalue;
      this.Avaule = item.avalue;
      this.Bvaule = item.bvalue;
      this.qtxHex = item.qtxHex;
      this.qtxName = item.name;
      this.refLInterval = item.refLInterval;
      this.refLLow = item.refLLow;
      this.refLPoints = item.refLPoints;
      this.reflectanceData = item.reflectanceData;
      if (this.idFlag !== item.idFlag) {
        this.idFlag = item.idFlag;
      }
      this.r = item.r;
      this.color1 = item.qtxHex;
      this.initActiveDiscount();
      this.updateDynamicBackgroundColor(item.qtxHex);
    },

    inputBlur() {
      var Lvaule = this.Lvaule;
      var Avaule = this.Avaule;
      var Bvaule = this.Bvaule;
      if (Lvaule) {
        var l = Lvaule.split(".");
        if (!l[0]) {
          this.Lvaule = 0;
        } else if (l.length >= 2 && l[1]) {
          this.Lvaule = l[0] + "." + l[1];
        } else {
          this.Lvaule = l[0];
        }
      } else {
        this.Lvaule = 0;
      }
      if (Avaule) {
        var a = Avaule.split(".");
        if (!a[0]) {
          this.Avaule = 0;
        } else if (a.length >= 2 && a[1]) {
          this.Avaule = a[0] + "." + a[1];
        } else {
          this.Avaule = a[0];
        }
      } else {
        this.Avaule = 0;
      }
      if (Bvaule) {
        var b = Bvaule.split(".");
        if (!b[0]) {
          this.Bvaule = 0;
        } else if (b.length >= 2 && b[1]) {
          this.Bvaule = b[0] + "." + b[1];
        } else {
          this.Bvaule = b[0];
        }
      } else {
        this.Bvaule = 0;
      }
    },
    selectName(brandName, deviceNo) {
      if (brandName && deviceNo) {
        return brandName + "-" + deviceNo;
      } else if (brandName && !deviceNo) {
        return brandName;
      } else if (!brandName && deviceNo) {
        return deviceNo;
      }
    },
    initDom() {
      if (this.$refs.ccccc) {
        let $div = this.$refs.c_top;
        let width =
          $div.style.width ||
          $div.clientWidth ||
          $div.offsetWidth ||
          $div.scrollWid;
        this.$refs.ccccc.style.width = width / 4 + "px";
        if (width / 4 < 300) {
          this.$refs.ccccc.style.width = "100px";
          this.$refs.ccccc.style.height = "47px";
        }
      }
    },
    enter(id) {
      this.activeId = id;
    },
    leave() {
      this.activeId = null;
    },

    // 获取色号分类列表
    getColorBookCategoryList() {
      colorBookCategoryListApi(0).then((records) => {
        if (records && records.length > 0) {
          this.colorBookCategoryList = records;
        } else {
          this.colorBookCategoryList = [];
        }
      });
    },

    //获取颜色
    getcolor(val) {
      this.searchType = 2;
      this.dataList = [];
      this.totalPage = 0;
      this.color1 = this.colorchange(val);
      this.qtxHex = this.color1;
      this.dataList = [];
      this.totalPage = 0;
      this.getlabvalue();
    },
    getlabvalue() {
      var hex = this.color1;
      var params = {
        hex: hex,
      };
      getLABApi(params).then((response) => {
        if (response) {
          this.RGBrvalue = response.rgblist[0];
          this.RGBgvalue = response.rgblist[1];
          this.RGBbvalue = response.rgblist[2];

          this.LCHlvalue = response.lchlist[0];
          this.LCHcvalue = response.lchlist[1];
          this.LCHhvalue = response.lchlist[2];

          this.Lvaule = response.lablist[0];
          this.Avaule = response.lablist[1];
          this.Bvaule = response.lablist[2];
        }
      });
    },
    getHexColor(hexStr) {
      let textColor = "#fff";
      if (!hexStr) return textColor; // 如果没有有效的hexStr，直接返回默认文本颜色

      const rgba = this.hexToRgba(hexStr);
      if (!rgba) return textColor; // 如果rgba转换失败，也返回默认颜色

      const hsv = this.rgb2hsv(rgba[0], rgba[1], rgba[2]);

      // 浅色或者黄色，字体要黑色的逻辑
      if ((hsv.s < 0.2 && hsv.v > 0.8) || (rgba[0] > 170 && rgba[1] > 170)) {
        textColor = "#333";
      }

      return textColor;
    },
    //rgb颜色转换hsv
    rgb2hsv() {
      var rr,
        gg,
        bb,
        r = arguments[0] / 255,
        g = arguments[1] / 255,
        b = arguments[2] / 255,
        h,
        s,
        v = Math.max(r, g, b),
        diff = v - Math.min(r, g, b),
        diffc = function (c) {
          return (v - c) / 6 / diff + 1 / 2;
        };

      if (diff == 0) {
        h = s = 0;
      } else {
        s = diff / v;
        rr = diffc(r);
        gg = diffc(g);
        bb = diffc(b);

        if (r === v) {
          h = bb - gg;
        } else if (g === v) {
          h = 1 / 3 + rr - bb;
        } else if (b === v) {
          h = 2 / 3 + gg - rr;
        }
        if (h < 0) {
          h += 1;
        } else if (h > 1) {
          h -= 1;
        }
      }
      return {
        h: h * 360,
        s: s,
        v: v,
      };
    },
    //hex转rgb
    hexToRgba(hex) {
      return [
        parseInt("0x" + hex.slice(1, 3)),
        parseInt("0x" + hex.slice(3, 5)),
        parseInt("0x" + hex.slice(5, 7)),
      ];
    },

    //rgb颜色转换hex
    colorchange(color) {
      //debugger
      var regexp = /[0-9]{0,3}/g;
      var re = color.match(regexp); //利用正则表达式去掉多余的部分，将rgb中的数字提取
      var hexColor = "#";
      var hex = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
      ];
      for (var i = 0; i < re.length; i++) {
        var r = null,
          c = re[i],
          l = c;
        var hexAr = [];
        while (c > 16) {
          r = c % 16;
          c = (c / 16) >> 0;
          hexAr.push(hex[r]);
        }
        hexAr.push(hex[c]);
        if (l < 16 && l != "") {
          hexAr.push(0);
        }
        hexColor += hexAr.reverse().join("");
      }
      return hexColor;
    },
    //重新查询
    restPage() {
      this.pageIndex = 1;
      this.getDataList();
      this.queryColorSampleApi();
    },
    // 重置查询条件
    reset(dataForm) {
      this.$nextTick(() => {
        this.$refs.dataForm.resetFields();
      });
      // this.Lvaule = "";
      // this.Avaule = "";
      // this.Bvaule = "";
      this.color1 = "";
      this.dataList = [];
      this.totalPage = 0;
      this.yarnType = [];
      this.yarnSpecification = [];
      this.composition = "";
      this.colorMatchType = "";
      this.productionProcess = "";
      this.colorMatchNumber = "";
      this.colorName = "";
      this.diffRange = 5.0;
      this.hasStock = "-1";
      if (this.searchData) {
        for (var i in this.searchData) {
          this.$set(this.searchData, i, {});
        }
        this.searchData = {};
      }
    },

    //查询按钮
    searchProductList() {
      if (this.Lvaule === 0 && this.Avaule === 0 && this.Bvaule === 0) {
        this.$message({
          message: this.$t("tips.message"),
          type: "warning",
        });
      }
      this.compareColors();
      if (this.searchType === 2) {
        this.r = "";
      }
      this.showSearch = true;
      this.tabsActiveFlag = true;
      if (this.tabsActiveName === "second") {
        // searchType = 1时 只需要查询标准色号
        if (this.searchType === 1 || this.productType.join() === "0D") {
          this.tabsActiveName = "second";
          this.activeName = "second";
          this.queryColorSampleApi();
        }
      } else {
        if (
          (this.groups.indexOf("1D") !== -1 ||
            this.groups.indexOf("2D") !== -1 ||
            this.groups.indexOf("1D,2D") !== -1)
        ) {
          this.$refs.dataForm.validate((valid) => {
            if (valid) {
              this.tabsActiveName = "first";
              if (this.tabsActiveName === "first") {
                this.activeName = "first";
              } else {
                this.activeName = "second";
              }

              this.getDataList();
            }
          });
        }
      }
    },

    // 勾选/取消勾选结果集事件
    chechboxClick(item) {
      this.compareColors();
      if (item.checked) {
        this.selectedData.push(item);
      } else {
        this.selectedData = this.selectedData.filter(
          (dataItem) => dataItem.id !== item.id
        );
      }
    },
    // 获取色样数据列表
    async getDataList() {
      const params = {
        tabsActiveName: this.tabsActiveName,
        productType: this.groups,
        searchType: this.searchType,
        l: this.Lvaule,
        a: this.Avaule,
        b: this.Bvaule,
        // 1D Yarn
        yarnType: this.yarnType,
        yarnSpecification: this.yarnSpecification,
        composition: this.composition,
        colorMatchType: this.colorMatchType,
        // 2D
        productionProcess: this.productionProcess,
        //高级
        colorMatchNumber: this.colorMatchNumber,
        colorName: this.colorName,

        hasStock: this.hasStock,
        // 查色
        colorDifferenceFormula: this.colorDifferenceFormula,
        illuminant: this.illuminant,
        observerAngle: this.observerAngle,
        diffRange: this.diffRange,

        state: this.colorSwatchCheckList.join(","),
        pageSize: this.pageSize,
        pageNo: this.pageIndex,
      };
      const res = await searchListApi(params);
      if ((res.records, res.totalRecords)) {
        if (res.records && res.records.length)
          this.dataList = res.records.map((item) => {
            return {
              ...item,
              checked: false,
            };
          });
        if (this.selectedData.length !== 0) {
          this.dataList.forEach((item) => {
            // 如果 selectedData 中有与 dataList 中相同 id 的对象，将 dateList 中的 checked 属性设置为 true
            if (
              this.selectedData.some(
                (selectedItem) => selectedItem.id === item.id
              )
            ) {
              item.checked = true;
            }
          });
        }

        this.totalPage = res.totalRecords;
        // this.totalPage = res.totalPages || 1
      } else {
        this.dataList = [];
      }
    },
    // 获取标准色号列表
    async queryColorSampleApi() {
      const params = {
        tabsActiveName: this.tabsActiveName,
        searchType: this.searchType,
        l: this.Lvaule,
        a: this.Avaule,
        b: this.Bvaule,
        // 1D Yarn
        yarnType: this.yarnType,
        yarnSpecification: this.yarnSpecification,
        composition: this.composition,
        colorMatchType: this.colorMatchType,

        // 2D
        productionProcess: this.productionProcess,
        //高级
        colorMatchNumber: this.colorMatchNumber,
        hasStock: this.hasStock,
        colorName: this.colorName,
        // 查色
        colorDifferenceFormula: this.colorDifferenceFormula,
        illuminant: this.illuminant,
        observerAngle: this.observerAngle,
        diffRange: this.diffRange,
        // 色号
        standardColor: this.standardColorCheckList.join(","),
        pageSize: this.pageSize,
        pageNo: this.pageIndex,
      };

      const { records, totalRecords } = await queryColorSampleApi(params);
      if (records) {
        if (records && records.length)
          this.dataList = records.map((item) => {
            return {
              ...item,
              checked: false,
            };
          });
        if (this.selectedData.length !== 0) {
          this.dataList.forEach((item) => {
            // 如果 selectedData 中有与 dataList 中相同 id 的对象，将 dateList 中的 checked 属性设置为 true
            if (
              this.selectedData.some(
                (selectedItem) => selectedItem.id === item.id
              )
            ) {
              item.checked = true;
            }
          });
        }

        this.totalPage = totalRecords;
      } else {
        this.dataList = [];
      }
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      if (this.tabsActiveName === "first") {
        this.getDataList();
      } else {
        this.queryColorSampleApi();
      }
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      if (this.tabsActiveName === "first") {
        this.getDataList();
      } else {
        this.queryColorSampleApi();
      }
    },
    // 产品详情
    cpdetailsHandle(item) {
      const { id, brandId } = item;
      if (this.tabsActiveName === "first") {
        this.cpdetailsVisible = true;
        this.$nextTick(() => {
          this.$refs.cpdetails.init({
            id,
            illuminant: this.illuminant,
            observerAngle: this.observerAngle,
            labL: this.Lvaule,
            labA: this.Avaule,
            labB: this.Bvaule,
            colorDifferenceFormula: this.colorDifferenceFormula,
          });
        });
      } else {
        this.sampleDetailsVisible = true;
        // seyang
        this.$nextTick(() => {
          this.$refs.sampleDetails.init({
            id,
            illuminant: this.illuminant,
            observerAngle: this.observerAngle,
            labL: this.Lvaule,
            labA: this.Avaule,
            labB: this.Bvaule,
            colorDifferenceFormula: this.colorDifferenceFormula,
          });
        });
      }
    },

    // 色差报表
    colorDifferenceReport() {
      this.cdreportVisible = true;
      let checkedlist = [...this.selectedData];
      checkedlist = checkedlist.filter((item) => item.checked);
      const standard = {
        id: 0,
        no: 0,
        name: this.qtxName,
        reflInterval: this.refLInterval,
        reflLow: this.refLLow,
        reflPoints: this.refLPoints,
        r: this.r,
      };

      // make samples
      var samples = [];

      // fix data
      checkedlist.forEach((item, index) => {
        let sample = {
          id: index + 1,
          no: index + 1,
          name:
            !item.name || (item.name == "" && item.colorSampleName != "")
              ? item.colorSampleName
              : item.name,
          reflInterval: item.reflInterval,
          reflLow: item.reflLow,
          reflPoints: item.reflPoints,
          r: item.r,
        };
        samples.push(sample);
      });

      colorCompareStore.methods.clearData();
      colorCompareStore.methods.setData(standard, samples, true);
      this.$forceUpdate();

      this.$router.push({ name: "colorCompare" });
    },

    //input框change事件
    searchTypeChange(value) {
      this.type = value;
      if (value == "1") {
        this.searchType = 1;
        this.searchTitle = this.$t(
          "search.accurateColorSearch.electronicColors.prompt"
        );
        if (
          this.colorBookCategoryList &&
          this.colorBookCategoryList.length > 0
        ) {
          this.colorNoType = this.colorBookCategoryList[0].id;
        }
        this.deviceType = "";
        this.dataList = [];
        this.totalPage = 0;
      } else if (value == "2") {
        this.searchType = 2;
        this.searchTitle = this.$t(
          "search.accurateColorSearch.colorSpace.prompt"
        );
        this.dataList = [];
        this.totalPage = 0;
      } else {
        this.searchTitle = this.$t(
          "search.accurateColorSearch.qtxSearch.prompt"
        );
        this.dataList = [];
        this.totalPage = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app-product-container {
  padding: 0px;
  background-color: #eaf1fc;
  position: relative;
}

.logo {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 172px;

  img {
    width: 604px;
    height: 104px;
  }
}

.main {
  background: #fff;
  margin-top: 20px;
}

.c_top {
  padding-bottom: 50px;
  border-bottom: 1px dotted #e0e3ec;
}

.c_main {
  padding-left: 50px;
  padding-top: 40px;
  padding-bottom: 50px;
  background: #fff;
}

.c_t_inputcontent {
  margin-top: -44px;
  margin-bottom: -30px;
  display: flex;
  align-items: end;
  justify-content: center;
  width: 100%;
}

.color-swatch-checkboxes {
  margin-top: 90px;
  display: flex;
  align-items: center;
}

.divTab {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 15px;
  margin-top: -20px;
  padding-top: 15px;
}

.tab-header {
  display: flex;
  align-items: center;
  padding: 20px 15px 15px 35px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}

.query-label {
  width: 100px;
  /* 调整宽度以适应你的需求 */
}

.tab-pane {
  margin-left: -40px;
}

.color-report {
  margin-left: 18px;
  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;
  height: 30px;
}

.content {
  background: #fff;
  padding: 5px;
}

.c_m_cards {
  justify-content: flex-start;

  // padding: 7.5px;
  .box {
    .checkbox {
      padding: 5px;
      box-sizing: border-box;
    }
  }
}

.c_m_c_img {
  padding: 10px;
  border-radius: 15px;
}
</style>
<style lang="scss">
.app-product-container {
  .el-radio-button:focus:not(.is-focus):not(:active) {
    -webkit-box-shadow: 0 0 2px 2px #fff !important;
    box-shadow: 0 0 2px 2px #fff !important;
  }

  .el-tabs__item.is-top {
    background: #f2f6fc;
    opacity: 1;
    border-radius: 4px;
  }

  .el-radio-button__inner {
    display: flex;
    align-items: center;
    border: 0;
    background: #e0e3ec;
    display: flex;
    justify-content: center;

    &.focus,
    &:focus,
    &:focus {
      border-color: #fff !important;
    }

    img {
      width: 19px;
      height: 19px;
      margin-right: 10px;
    }
  }

  .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    border-color: #e0e3ec !important;
    box-shadow: none !important;
  }

  .el-radio-button {
    width: 220px;
  }

  .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    background: url("../../../assets/pro_tabs.png") no-repeat;
    color: #7073ff;
    border-color: #e0e3ec;
    box-shadow: none;
    border: 0;
    background-size: contain;
  }

  .el-tabs--card>.el-tabs__header {
    border: 0;
  }

  .el-tabs__nav.is-top {
    border: 0;
  }

  .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
    background: #7073ff;
    color: #fff;
    position: relative;
    border-radius: 4px !important;

    &::before {
      content: "";
      width: 0px;
      height: 0px;
      border-top: 9px solid #7073ff;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      position: absolute;
      top: 38px;
      left: 60px;
    }
  }

  .el-tabs__nav-wrap,
  .el-tabs__nav-scroll {
    overflow: auto;
    padding-left: 17.5px;
  }

  .el-tabs__nav.is-top {
    height: 50px;

    div {
      &:nth-child(2) {
        background: #f2f6fc;
        margin-left: 15px;
        border: 0 !important;
        border-radius: 4px !important;
      }
    }
  }

  .el-tabs.el-tabs--card.el-tabs--top {
    height: 50px;
  }

  .el-card {
    position: relative;
  }

  .layout-mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    //bottom: 50%;
    height: 170px;
    background: rgba(0, 0, 0, 0.32);
    opacity: 1;

    .main-mask {
      opacity: 1;
      position: absolute;
      display: flex;
      justify-content: center;
      bottom: 10px;
      left: 0;
      right: 0;
    }
  }

  // .el-card__body {
  //   cursor: pointer;
  // }
  .el-button.btn.el-button--primary.is-disabled {
    width: 153px;
    background: rgba(255, 176, 72, 0.09);
    border: 2px solid #ffab3d;
    opacity: 1;
    border-radius: 2px;
    color: #ffab3d;
    padding-left: 0;

    >span {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      position: relative;

      img {
        position: absolute;
        top: -6px;
        right: -10px;
      }
    }
  }

  .el-input--suffix .el-input__inner {
    border-radius: 0;
  }

  .el-radio-group {
    // background: url("@/assets/pro_tabs1.png");
    // background-size: 100%;
    // background-position-x: 100px;
    // width: 100%;
  }

  .el-input-group {
    width: 226px;
  }

  .c_t_inputcontent {
    .el-button.btn.el-button--primary {
      padding: 0 15px;
      background: rgba(255, 176, 72, 0.09);
      border: 2px solid #ffab3d;
      opacity: 1;
      border-radius: 2px;
      color: #ffab3d;

      span {
        display: flex;
        align-items: center;

        img {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.divTab .el-tabs__nav.is-top {
  float: left;
}

.spanSize {
  font-size: 14px;
}

.app-product-container {
  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: none !important;
  }

  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: none !important;
  }

  .el-checkbox__label {
    font-size: 16px !important;
  }
}

#fenye {
  width: 100%;

  .number,
  .btn-quicknext {
    display: none;
  }

  .active {
    display: block;
  }

  .el-pagination {
    margin-top: 15px;
    text-align: right;
    margin-right: 50px;
  }
}

/* .el-icon-my-export{
    background: url(@/assets/yqt.png) center no-repeat;
    background-size: cover;
} */
.c_t_i_l_top .el-input__inner {
  border: 0;
  border-radius: 0;
  min-width: 128px;
  height: 37px;
  background: rgba(245, 245, 245, 0.6);
  text-align: center;
}

.c_m_cards .el-card {
  width: 230px;
  /*height: 240px;*/
  margin-left: 19px;
  margin-bottom: 20px;
}

.c_top .el-color-picker__trigger .el-icon-arrow-down:before {
  content: url("../../../assets/img/seBan2.png") !important;
  font-size: 18px;
}

.c_top .el-icon-close:before {
  content: url("../../../assets/img/seBan2.png") !important;
  font-size: 18px;
}

.c_top .el-color-picker__trigger {
  border: none;
  padding: 0;
  margin: -10px;
  height: 40px;
  width: 38px;
  background-color: cornflowerblue;
}

.c_top .el-color-picker__color {
  border: none !important;
}

.el-color-dropdown__btns button {
  display: none !important;
}

// .c_top .el-color-dropdown__link-btn {
//   display: none  !important;
// }
// .c_top .el-color-dropdown__btn {
//   display: none  !important;
// }
.el-input-group__prepend {
  background-color: #e4e4e4;
  color: #303133;
  vertical-align: middle;
  display: table-cell;
  position: relative;
  border: 0px solid #dcdfe6;
  border-radius: 0px;
  padding: 0 20px;
  width: 0px;
  white-space: nowrap;
  height: 38px;
}

.w100 {
  width: 100px;
}

.mr10 {
  margin-right: 10px;
}

.ml10 {
  margin-left: 10px;
}

.c_top {
  text-align: center;
}

.c_t_title {
  font-size: 28px;
  color: #666666;
  margin-bottom: 10px;
}

.c_t_title span {
  color: #009cff;
}

.c_t_i_l_top {
  display: flex;
  justify-content: center;
  background: rgba(245, 245, 245, 0.6);
  align-items: center;
}

.c_t_i_l_top .color {
  width: 23px;
  height: 23px;
}

.color-chunk-box {
  position: relative;

  .color {
    position: absolute;
    top: 18px;
    right: 17px;
  }
}

.c_tips {
  color: #999999;
  font-size: 12px;
}

.btn {
  height: 40px;
  border-radius: 2px;
  margin-left: 10px;
}

.btn-input {
  height: 40px;
  border-radius: 2px;
  margin-left: 10px;
}

.c_t_colorcontent {
  width: 315px;
  height: 115px;
  display: flex;
  float: left;
  justify-content: space-between;
  align-items: self-start;
  color: #ffffff;
  font-size: 18px;
  padding: 14px;
  margin: 10px 0 10px 0px;
  display: flex;
  flex-direction: column;
  font-size: 16px;

  p {
    margin: 0;
  }

  img {
    width: 28px;
    height: 28px;
  }
}

.c_t_color_history {
  float: left;
  margin-left: 30px;

  p {
    margin: 10px 0 0;
  }

  .success {
    font-size: 30px;
    font-weight: bold;
    color: #67c23a;
  }
}

.c_main {
  // border: 1px #f0d1d1 solid;
  padding: 15px 0;
  margin-top: 10px;
}

.c_m_c_img {
  width: 100%;
  height: 160px;
}

.c_m_c_img_ {
  position: relative;
  width: calc(100% - 20px);
  height: 140px;
  margin: 10px;
  border-radius: 5px;
}

.inventory-img {
  width: 20px;
  height: 20px;
  position: absolute;
  /* 绝对定位 */
  bottom: 10px;
  /* 距离底部 10px */
  right: 10px;
  /* 距离右侧 10px */
}

.c_m_cards {
  display: flex;
  flex-wrap: wrap;
  min-height: 240px;
}

.c_m_c_bot {
  color: #333333;
  font-size: 15px;
  padding: 0 10px;
}

.c_m_c_b_txt {
  font-size: 12px;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.c_m_c_b_txt span {
  color: #999999;
  margin-right: 47px;
  width: 60px;
  display: inline-block;
}

.c_t_radiocontent {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  -webkit-box-align: self-start;
  -ms-flex-align: self-start;
  align-items: self-start;
  color: #ffffff;
  font-size: 18px;
  background: #e0e3ec;
  position: relative;
  height: 40px;
  overflow: hidden;
}

.qtx_column_0 {
  justify-content: right;
  width: 390px;
  padding-right: 60px;
  margin-left: 43px;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: end;
}

.qtx_column_2 {
  // min-width: 400px;
  height: 300px;
  display: flex;
  align-items: end;
  // margin-right: 60px;

  .c_t_i_left {
    margin-right: 30px;
    border: none;
  }

  .select-left {
    margin-bottom: 90px;
  }

  .color-code-select {
    margin-top: 20px;

    input {
      font-size: 24px;
      height: 50px;
      padding: 8px;
      padding-left: 12px;
    }
  }

  .color-code-label {
    text-align: left;
    padding-left: 10px;
    margin-bottom: 4px;
    color: grey;
    font-weight: 500;
  }

  .el-input-group__prepend {
    color: #303133;
    vertical-align: middle;
    display: table-cell;
    border: 1px solid #dcdfe6;
    border-radius: 0px;
    width: 64px;
    white-space: nowrap;
    height: 40px;
    padding: 0px;
  }
}

.qtx_color_div_4 {
  height: 262px;
  width: 340px;
  border: 1px solid #ccc;
}

.qtx_lab_dav_4 {
  height: 40px;
  width: 400px;
  margin-left: -70px;
}

.qtx_lab_value {
  display: inline-block;
  border: 1px solid #cccccc;
  padding: 8px;
}

.echarts-chart_4 {
  width: 370px;
  height: 350px;
}

.qtx_upload_4 {
  margin-bottom: 20px;
  position: relative;

  .el-upload-list {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    bottom: -25px;
    left: 1%;
  }

  .el-upload-dragger {
    background-color: #fff;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 290px;
    height: 150px;
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-top: 86px;
    font-size: 10px;
    padding: 12px;

    .el-upload__tip_4 {
      margin-top: 20px;
    }
  }

  .el-upload-dragger .el-icon-upload {
    font-size: 60px;
    color: #c0c4cc;
    margin: 36px 0 0px;
    line-height: 3px;
  }

  .el-upload-list__item-name {
    color: #606266;
    display: block;
    margin-right: 40px;
    overflow: hidden;
    padding-left: 4px;
    text-overflow: ellipsis;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    white-space: nowrap;
    width: 302px;
  }
}

.qtx_column_3 {
  width: 400px;
  height: 300px;
  margin-left: 30px;
}

.choose-color-body {
  display: flex;
  align-items: center;
  padding: 63px 0;
  box-sizing: border-box;

  .color-item {
    color: #000;
    height: 45px;
    line-height: 45px;
    font-size: 14px;
    cursor: pointer;

    .title {
      position: relative;
      box-sizing: border-box;
      width: 160px;
      text-align: center;
      overflow: hidden;
    }

    .activeColorTab {
      color: #7073ff;

      &::after {
        content: " ";
        position: absolute;
        right: 40px;
        top: 12px;

        width: 3px;
        height: 20px;
        background-color: #7073ff;
      }
    }
  }

  .right-content {
    .item {
      height: 45px;
      line-height: 45px;
      display: flex;
      align-items: center;
      font-weight: bold;
      margin-left: 20px;

      .color-input {
        border: 1px solid #f1f1f1;
        margin-left: 20px;
      }
    }
  }
}

.qtx_column_1 {
  width: 230px;
  height: 260px;
  overflow-y: scroll;
  margin-right: 20px;
  margin-left: -37px;
  margin-top: 55px;
}

.qtx_column_1::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条宽度 */
}

.qtx_column_1::-webkit-scrollbar-thumb {
  background-color: #888;
  /* 设置滚动条拖动部分的背景颜色 */
  border-radius: 6px;
  /* 设置滚动条拖动部分的边角半径 */
}

.qtx_column_1::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color, #f2f2f2);
  border-radius: 8px;
  /* 使用变量设置滚动条轨道的背景颜色 */
}

.qtx_column_1::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* 设置滚动条拖动部分的背景颜色（悬停时） */
}

.qtx_column_1::-webkit-scrollbar-corner {
  background-color: #f2f2f2;
  /* 设置滚动条的角落的背景颜色 */
}

.qtx_column_1_0 {
  width: 200px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.qtx_column_1_0 :hover {
  color: #1f22d9;
  font-weight: bold;
  cursor: pointer;
}

.qtx_column_name {
  width: 150px;
  font-size: 10px;
  float: left;
}

.qtx_column_1_0_0 {
  width: 25px;
  height: 25px;
  float: right;
}

.qtx_column_1_0_0:hover {
  width: 30px;
  height: 30px;
  float: right;
}

.qtx_el_form_item_4 {
  margin-bottom: 0px;
  display: flex;
  justify-content: right;
  margin-top: 30px;
  width: 180px;

  .el-form-item__content {
    line-height: 40px;
    position: relative;
    font-size: 14px;
    width: auto;
    flex: 1;
  }

  .el-form-item__label {
    width: 78px !important;
    text-align: left;
    width: 100px;
  }
}

.select-input-item {
  display: flex;
  align-items: center;
  width: 450px;
  height: 56px;

  .color-chunk {
    width: 50px;
    height: 50px;
  }

  .color-name {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 400;
    width: 450px;
    overflow: hidden;
    // white-space: nowrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    // overflow-wrap: break-word;
  }
}

.filter-type {
  padding-left: 20px;
  display: flex;
  align-items: center;
  margin-top: -15px;
}

.oned-bar {
  min-height: 50px;
  width: 3px;
  background-color: #373739;
  display: inline-block;
  margin: 5px;
}

.search-1d-text {
  display: inline;
  margin-left: 5px;
}

.customLabel {
  font-weight: 500;
  font-size: 12px;
  color: grey;
  margin-bottom: -5px;
  display: block !important;
  width: 90px;
  text-align: right;
}

.filter-form {
  width: 105%;
  min-width: 800px;
  max-width: 1200px;
}

// @media screen and (max-width: 1600px) {
.customLabel {
  text-align: left;
  width: 150px;
}

.filter-type {
  margin-top: 0px;
  padding-left: 0px;
}

.filter-form {
  min-width: 800px;
  max-width: 1100px;
}

// }

.filter-property {
  width: 90%;
  margin-right: 5%;
  display: block !important;
}

.filter-property-select {
  width: 83%;
  margin-right: 5%;
  display: block !important;
}

.filter-property-num {
  width: 83%;
  display: block !important;
  margin-right: -25px;
  margin-left: 1px;
}

::v-deep .el-select {
  width: 100%;
}
</style>
<style lang="scss">
::v-deep .el-select {
  width: 100%;
}

.el-autocomplete {
  margin-left: 10px;
  width: 230px;
  height: 32px;

  .el-input__inner {
    height: 32px;
  }
}

.checkbox-group {
  margin-top: -20px;
  margin-bottom: 40px;
}

.el-tab-pane {
  .form-1d {
    margin-left: -30px;
  }

  .form-2d {
    margin-left: -30px;
    margin-top: 45px;
  }

  .form-edye {
    margin-left: -30px;
    margin-top: 85px;
  }

  .form-searchColor {
    margin-top: 125px;
    width: 1000px;
  }
}

.app-product-container .el-tabs--card>.el-tabs__header .el-tabs__item.is-active::before {
  content: "";
  width: 0px;
  height: 0px;
  border-top: 9px solid #7073ff;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  position: absolute;
  top: 38px;
  left: 50px;
}

.select-box {
  display: flex;
  justify-content: center;
}

.search-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
  /* 左对齐 */
}

.c_t_title_hint {
  width: 100%;
  height: 50px;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 20px;
}
</style>
