<template>
  <el-dialog
    :title="!dataForm.id ? '处理' : '处理'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="600px"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      size="small"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="120px"
      v-loading="dataLoading"
      style="width: 100%"
      element-loading-text=""
      element-loading-spinner="el-icon-loading"
    >
      <el-form-item label="处理操作" prop="operation">
        <el-radio-group v-model="dataForm.operation">
          <el-radio v-model="dataForm.operation" :label="2">完全匹配</el-radio>
          <el-radio v-model="dataForm.operation" :label="4"
            >配色可参考</el-radio
          >
          <el-radio v-model="dataForm.operation" :label="3">不匹配</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="remarks" label="搜索备注">
        <el-input
          size="small"
          placeholder="请输入搜索备注"
          v-model="dataForm.remarks"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="sampleOrderNo" label="打样单号">
        <el-input
          size="small"
          placeholder="请输入打样单号"
          v-model="dataForm.sampleOrderNo"
        >
        </el-input>
      </el-form-item>

      <el-form-item v-if="dataForm.operation == '3'" prop="reason" label="原因">
        <el-autocomplete
          v-model="dataForm.reason"
          :fetch-suggestions="querySearch"
          placeholder="请输入原因"
          style="width: 100%"
        ></el-autocomplete>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false" size="small">取消</el-button>
      <el-button
        type="primary"
        @click="dataFormSubmit()"
        size="small"
        :disabled="dataLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {
  getSearchHistoryApi,
  updateSearchHistoryApi
} from '@/api/modules/searchHistory'
export default {
  data() {
    return {
      searchForm: [],
      searchData: {},
      testList: [
        {
          value: '目视差异',
          label: '目视差异'
        },
        {
          value: '光泽不匹配',
          label: '光泽不匹配'
        },
        { value: '表面状态不匹配', label: '表面状态不匹配' },
        { value: '系列不匹配', label: '系列不匹配' }
      ],
      visible: false,
      dataLoading: false,
      colorSeriesList: [],
      ProductCategoryList: [],
      dataForm: {
        id: undefined,
        operation: '',
        sampleOrderNo: '',
        reason: '',
        remarks: ''
      },
      dataRule: {
        productCategory: [
          { required: true, message: '产品分类不能为空', trigger: 'blur' }
        ],
        reason: [
          { required: true, message: '原因不能为空', trigger: 'change' }
        ],
        operation: [
          { required: true, message: '处理操作不能为空', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    querySearch(queryString, cb) {
      var results = queryString
        ? this.testList.filter(
            (item) =>
              item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
          )
        : this.testList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    init(id) {
      this.dataForm.id = id || undefined
      this.visible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.id) {
          getSearchHistoryApi(this.dataForm.id).then((response) => {
            this.dataForm = response
          })
        }
      })
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.dataLoading = true
          updateSearchHistoryApi(this.dataForm).then(() => {
            this.$baseMessage('删除成功', 'success')
            this.visible = false
            this.dataLoading = false
            this.$emit('refreshDataList')
          })
        }
      })
    }
  }
}
</script>
