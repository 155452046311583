import request from "@/utils/request";

const url = "/c/attrValue";





// 获取列表
export function queryAttrValueApi(data) {
    if (!data) {
        data = { pageSize: 100000 }
    }
    return request({
        url: `${url}/query`,
        method: 'post',
        data
    })
}

// 获取信息
export function getAttrValueApi(id) {
    return request({
        url: `${url}/get/${id}`,
        method: 'get',
    })
}

// 添加
export function addOrUpdateAttrValueApi(data) {
    return request({
        url: `${url}/addOrUpdate`,
        method: 'put',
        data
    })
}


export function queryAttrValueBykeyApi(data) {
    return request({
        url: `${url}/queryAttrValueBykey`,
        method: 'post',
        data
    })
}


// 删除
export function deleteAttrValueByIdApi(id) {
    return request({
        url: `${url}/delete/${id}`,
        method: 'delete',
    })
}

//类型下拉
export function getTypeDictInfoApi() {
    return request({
        url: `${url}/getTypeDictInfo`,
        method: 'get'
    })
}
