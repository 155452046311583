import request from '@/utils/request'

const url = '/c/colorSample'

/**
 * 分页获取色样列表信息
 * @returns
 */
export function searchColorSampleApi(data) {
    return request({
        url: `${url}/searchColorSample`,
        method: 'post',
        data
    })
}

export function viewDetailsApi(data) {
    return request({
        url: `${url}/viewDetails`,
        method: 'post',
        data
    })
}

// 删除
export function deleteColorSampleApi(id) {
    return request({
        url: `${url}/delete/${id}`,
        method: 'delete'
    })
}

export function getStockDataApi(data) {
    return request({
        url: `${url}/getStockData`,
        method: 'post',
        data
    })
}



export function controlShowHideApi(data) {
    return request({
        url: `${url}/updateShowHide`,
        method: 'post',
        data
    })
}