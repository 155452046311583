const colorCompareStore = {
    data: {
        standard: {},
        samples: [],
        fromSearch: false
    },
    methods: {
        setData(standard, samples, fromSearch) {
            colorCompareStore.data.standard = standard;
            colorCompareStore.data.samples = samples;
            colorCompareStore.data.fromSearch = fromSearch;

        },
        addSamples(samples) {
            colorCompareStore.data.samples.push(samples);
        },
        clearData() {
            colorCompareStore.data.standard = {};
            colorCompareStore.data.samples = [];
            colorCompareStore.data.fromSearch = false;
        }
    }

};

export default colorCompareStore;