<template>
  <el-select
    v-model="selectedValue"
    :size="size"
    :style="{ width: width + 'px' }"
    @change="illuminantChange"
  >
    <el-option
      v-for="item in illuminantList"
      :key="item"
      :label="item"
      :value="item"
    ></el-option
  ></el-select>
</template>

<script>
import { getIlluminantTextsApi } from "@/api/modules/colorbook";

export default {
  props: ["illuminant", "size", "width"],
  data() {
    return {
      selectedValue: "",
      illuminantList: [],
    };
  },
  mounted() {
    this.getIlluminantTexts();
    this.selectedValue = this.illuminant;
  },
  methods: {
    // 获取观察者数据
    getIlluminantTexts() {
      getIlluminantTextsApi().then((records) => {
        if (records.length > 0) {
          this.illuminantList = records;
          if (records.includes("D65")) {
            this.selectedValue = "D65";
          } else {
            this.selectedValue = records[0];
          }
          this.illuminantChange();
        } else {
          this.illuminantList = [];
        }
      });
    },
    illuminantChange() {
      this.$emit("change", this.selectedValue);
    },
  },
};
</script>