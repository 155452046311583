import request from '@/utils/request'

const url = '/c/colorBook'

// 获取列表
export function queryColorBookApi(data) {
  return request({
    url: `${url}/query`,
    method: 'post',
    data
  })
}

export function getColorBookListApi(params) {
  return request({
    url: `${url}/getColorBookList`,
    method: 'get',
    params
  })
}


export function queryColorCars(data) {
  return request({
    url: `${url}/queryColorCars`,
    method: 'post',
    data
  })
}

export function queryColorSampleApi(data) {
  return request({
    url: `${url}/queryColorSample`,
    method: 'post',
    data
  })
}

// QTX文件导入
export function importQtx(params) {
  return request({
    url: `${url}/importQtx`,
    method: 'post',
    data: params
  })
}

// 获取光源
export function getIlluminantTextsApi() {
  return request({
    url: `${url}/getIlluminantTexts`,
    method: 'post',
  })
}

// 获取观察者角度
export function getObserverAngleTextsApi() {
  return request({
    url: `${url}/getObserverAngleTexts`,
    method: 'post',
  })
}

export function colorBookViewDetailsApi(data) {
  return request({
    url: `${url}/viewDetails`,
    method: 'post',
    data
  })
}

// 删除
export function deleteColorBookDataApi(id) {
  return request({
    url: `${url}/delete/${id}`,
    method: 'delete',
  })
}